import React from "react";
import { MaxWidthWrapper } from "../../ui/MaxWidth";
import { GroupTitle } from "../../ui/GroupTitle";
import { ContestPageTitle } from "../CategoryStartPage";

const ResultList = ({ items, title }) => {
  return (
    <MaxWidthWrapper>
      <ContestPageTitle>{title}</ContestPageTitle>
      {items?.map((item) => (
        <GroupTitle key={item.id} id={item.id} title={item.name} />
      ))}
    </MaxWidthWrapper>
  );
};

export default ResultList;
