import React, { useState, useEffect } from "react";
import { auth } from "../../firebase-config";
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { Input } from "../ui/Input";
import { Button } from "../ui/Button";
import AlertBox from "../ui/AlertBox";
import { MaxWidthWrapper } from "../ui/MaxWidth";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";

export const LoginWrapper = styled.div`
  &,
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    input {
      margin: 0 0 1rem 0;
    }
    button {
      width: 100%;
    }
  }
  a.text-link {
    margin-top: 1rem;
  }
`;

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is already logged in
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate("/my-pages"); // Redirect to My Pages if logged in
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/"); // Redirect to the homepage after successful login
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <MaxWidthWrapper>
      <LoginWrapper>
        <h2>Logga in</h2>
        <form onSubmit={handleLogin}>
          <Input
            type="email"
            placeholder="E-post"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <Input
            type="password"
            placeholder="Lösenord"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <Button type="submit">Logga in</Button>
        </form>
        {error && <AlertBox variant="danger">{error}</AlertBox>}
        <Link className="text-link forgot" to="/forgot-password">
          Glömt lösenord?
        </Link>
        <Link className="text-link register" to="/register">
          Registrera dig
        </Link>
      </LoginWrapper>
    </MaxWidthWrapper>
  );
};

export default Login;
