import React from "react";
import styled from "styled-components";
import { MaxWidthWrapper } from "../../../ui/MaxWidth";
import GroupSchedule from "./GroupSchedule";
import { ResultsTable } from "../../Results/ResultsTable";
import { ContestPageTitle } from "../../CategoryStartPage";
import {
  CategoryItemProvider,
  useCategoryItem,
} from "../../CategoryItem/CategoryItemContext";
import { Outlet, useLocation } from "react-router";

export const GroupWrapper = styled.div``;

export const Group = () => {
  const { item: group } = useCategoryItem();
  const location = useLocation();

  if (!group) return null;

  const isChildrenRoute = /\/pooler\/[^/]+\/match/.test(location.pathname);
  if (isChildrenRoute) return <Outlet />;

  return (
    <MaxWidthWrapper>
      <GroupWrapper>
        <ContestPageTitle className="group-name">{group.name}</ContestPageTitle>
        <ResultsTable group={group} />
        <GroupSchedule group={group} />
      </GroupWrapper>
    </MaxWidthWrapper>
  );
};

const GroupMain = () => (
  <CategoryItemProvider itemType="groups">
    <Group />
  </CategoryItemProvider>
);

export default GroupMain;
