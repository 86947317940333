import React from "react";
import { Outlet, useLocation } from "react-router";
import styled from "styled-components";
import { NoWidthWrapper } from "../../../ui/MaxWidth";
import {
  CategoryItemProvider,
  useCategoryItem,
} from "../../CategoryItem/CategoryItemContext";
import { ContestPageTitle } from "../../CategoryStartPage";
import KnockoutTree from "./KnockoutTree";

const KnockoutWrapper = styled.div``;

const Knockout = () => {
  const { item: knockout } = useCategoryItem();
  const location = useLocation();

  if (!knockout) return null;

  const isChildrenRoute = /\/slutspel\/[^/]+\/match/.test(location.pathname);
  if (isChildrenRoute) return <Outlet />;

  return (
    <NoWidthWrapper>
      <KnockoutWrapper>
        <ContestPageTitle className="knockout-name">
          {knockout.name}
        </ContestPageTitle>
        <KnockoutTree />
      </KnockoutWrapper>
    </NoWidthWrapper>
  );
};

const KnockoutMain = () => (
  <CategoryItemProvider itemType="knockouts">
    <Knockout />
  </CategoryItemProvider>
);

export default KnockoutMain;
