import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase-config";
import {
  listenToCategories,
  createCategory,
  createNewCategory,
} from "../Database/categoryService";

const CategoryListContext = createContext();

export const CategoryListProvider = ({ children }) => {
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(false);
  const navigate = useNavigate(); // Använd för att navigera

  const removeError = () => {
    setError(false);
  };

  useEffect(() => {
    // Använd listenToCategories för att lyssna på förändringar i realtid
    const userId = auth.currentUser?.uid;
    const userEmail = auth.currentUser?.email;
    const unsubscribe = listenToCategories(
      userId,
      userEmail,
      (updatedCategories) => {
        setCategories(updatedCategories);
      }
    );

    // Avsluta lyssnaren vid komponentens demontering
    return () => {
      unsubscribe();
    };
  }, []);

  const validateCategory = (category) => {
    for (const key in category) {
      if (category[key] === undefined) {
        throw new Error(`Field "${key}" is undefined`);
      }
      if (typeof category[key] === "object" && category[key] !== null) {
        validateCategory(category[key]); // Rekursiv validering för objekt
      }
    }
  };

  const addCategory = async ({ name, visibility, date, time, location }) => {
    const user = auth.currentUser;

    if (!user) {
      setError("Du måste vara inloggad för att skapa en kategori");
      navigate("/login"); // Omdirigera till inloggningssidan
      return;
    }

    const newCategory = createNewCategory({
      name,
      location,
      visibility,
      date,
      time,
      owner: {
        id: user.uid,
        name: user.displayName || "Okänd användare",
      },
    });

    try {
      validateCategory(newCategory);
      const id = await createCategory(newCategory);
      navigate(`/category/${id}`);
    } catch (createError) {
      setError(createError.message);
      return;
    }
  };

  const contextValue = {
    categories,
    addCategory,
    error,
    removeError,
  };

  return (
    <CategoryListContext.Provider value={contextValue}>
      {children}
    </CategoryListContext.Provider>
  );
};

export const useCategoryList = () => useContext(CategoryListContext);
