import React from "react";
import styled from "styled-components";
import { useCategory } from "../../CategoryContext";
import { Match } from "../../Match/Match";

const ScheduleWrapper = styled.div`
  text-align: left;
  .round {
    margin: 20px 0;
    padding: 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    > h2 {
      background: var(--color-primary-light);
      padding: 1rem;
      margin: 0;
      font-size: 1rem;
    }
  }
`;

const GroupSchedule = ({ group }) => {
  const { groupsIsLocked } = useCategory();
  return (
    <ScheduleWrapper>
      {group.rounds?.map((round, roundIndex) => (
        <div key={roundIndex} className="round">
          <h2>Omgång {roundIndex + 1}</h2>
          {round.map((match, matchIndex) => {
            return (
              <Match
                key={matchIndex}
                match={match}
                reportIndex={[roundIndex, matchIndex]}
                locked={groupsIsLocked}
              />
            );
          })}
        </div>
      ))}
    </ScheduleWrapper>
  );
};

export default GroupSchedule;
