import {
  collection,
  doc,
  addDoc,
  updateDoc,
  deleteDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "../../firebase-config";

//CATEGORY CRUD
export const createCategory = async (category) => {
  const categoriesRef = collection(firestore, "categories");
  if (!category.owner || !category.owner.id) {
    throw new Error("Owner ID is required to create a category.");
  }
  const docRef = await addDoc(categoriesRef, category);
  return docRef.id;
};

export const updateCategory = async (category) => {
  if (!category.id) {
    throw new Error("Category ID is required to update a category.");
  }
  const categoryDoc = doc(firestore, "categories", category.id);
  await updateDoc(categoryDoc, category);
};

export const deleteCategory = async (id) => {
  if (!id) {
    throw new Error("Category ID is required to delete a category.");
  }
  const categoryDoc = doc(firestore, "categories", id);
  await deleteDoc(categoryDoc);
};

export const listenToCategory = (id, callback) => {
  if (!id) {
    throw new Error("Category ID is required to listen to a category.");
  }
  const categoryDoc = doc(firestore, "categories", id);
  return onSnapshot(categoryDoc, (docSnapshot) => {
    callback(
      docSnapshot.exists()
        ? {
            id: docSnapshot.id,
            ...docSnapshot.data(),
          }
        : null
    );
  });
};

//CATEGORY LISTING
export const listenToCategories = (userId, userEmail, callback) => {
  const categoriesRef = collection(firestore, "categories");

  // Query för offentliga kategorier
  const publicQuery = query(categoriesRef, where("visibility", "==", "public"));

  // Lyssna på offentliga kategorier (kör alltid)
  const unsubscribePublic = onSnapshot(publicQuery, (snapshot) => {
    const publicCategories = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    callback((prev) => [
      ...prev.filter((cat) => cat.visibility !== "public"),
      ...publicCategories,
    ]);
  });

  let unsubscribeOwner = () => {};
  let unsubscribeReporter = () => {};

  // Om userId finns, lyssna på ägda kategorier
  if (userId) {
    const ownerQuery = query(categoriesRef, where("owner.id", "==", userId));
    unsubscribeOwner = onSnapshot(ownerQuery, (snapshot) => {
      const ownerCategories = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      callback((prev) => [
        ...prev.filter((cat) => cat.owner?.id !== userId),
        ...ownerCategories,
      ]);
    });
  }

  // Om userEmail finns, lyssna på kategorier där användaren är reporter
  if (userEmail) {
    const reporterQuery = query(
      categoriesRef,
      where("reporters", "array-contains", userEmail)
    );
    unsubscribeReporter = onSnapshot(reporterQuery, (snapshot) => {
      const reporterCategories = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      callback((prev) => [
        ...prev.filter(
          (cat) => !(cat.reporters && cat.reporters.includes(userEmail))
        ),
        ...reporterCategories,
      ]);
    });
  }

  // Returnera funktion för att avsluta lyssnarna
  return () => {
    unsubscribePublic(); // Kör alltid
    if (userId) unsubscribeOwner(); // Kör om userId finns
    if (userEmail) unsubscribeReporter(); // Kör om userEmail finns
  };
};

export const initialCategory = {
  name: "",
  type: "group-knockout",
  groupAmount: 4,
  set: 3,
  players: [],
  groups: [],
  knockouts: [],
  lock: {
    players: false,
    groups: false,
    knockouts: false,
  },
  visibility: "public",
  date: "",
  time: "",
  matchTime: 20,
  matchReport: "set",
  location: "",
  owner: {
    id: "",
    name: "",
  },
  reporters: [],
};

export const createNewCategory = ({
  name,
  visibility = "public",
  date = "",
  time,
  owner,
  location,
}) => {
  const newCategory = {
    ...initialCategory,
    name,
    visibility,
    date,
    time,
    owner,
    location,
  };
  return newCategory;
};
