import { timeToMinutes } from "../../util/helpers";

export const sortObjectByKeys = (obj) => {
  const sortedEntries = Object.entries(obj).sort(([keyA], [keyB]) => {
    return keyA.localeCompare(keyB);
  });

  return Object.fromEntries(sortedEntries);
};

export const isStageCompleted = (stages) => {
  if (!stages || stages.length === 0) return false;
  return stages.every((stage) =>
    stage.rounds.every((round) =>
      round.every((match) => {
        const hasWinner = match.winner && match.winner.id;
        const isBye =
          match.player1?.name === "bye" || match.player2?.name === "bye";
        return hasWinner || isBye;
      })
    )
  );
};

export const isStageOngoing = (stages) => {
  if (!stages || stages.length === 0) return false;
  return stages.some((stage) =>
    stage.rounds.some((round) =>
      round.some((match) => {
        const hasWinner = match.winner && match.winner.id;
        const isBye =
          match.player1?.name === "bye" || match.player2?.name === "bye";
        return hasWinner && !isBye;
      })
    )
  );
};

export const getCategoryItemStatus = (stage) => {
  if (!stage || !stage.rounds || stage.rounds.length === 0) {
    return "Ej startad"; // Gruppen har inga matcher ännu
  }
  if (isStageCompleted([stage])) return "Avslutad";
  if (isStageOngoing([stage])) return "Pågående";
  return "Ej startad";
};

export const isGroupKnockout = (type) =>
  type === "group-knockout" || type === "group-knockout-multi";

export const hasGroupsInCategory = (type) =>
  isGroupKnockout(type) || type === "round-robin";

export const hasKnockoutInCategory = (type) =>
  isGroupKnockout(type) || type === "knockout";

export const getGroupDelay = (group) => {
  if (!group || !group.rounds || group.rounds.length === 0) {
    return 0; // Om det inte finns några matcher, ingen fördröjning
  }

  const allMatches = group.rounds.flat(); // Sammanfoga alla matcher från alla rundor

  // Filtrera bort matcher som inte har en adjustedTime
  const completedMatches = allMatches.filter(
    (match) => match.adjustedTime !== undefined
  );

  if (completedMatches.length === 0) {
    return 0; // Om inga matcher har adjustedTime, ingen fördröjning
  }

  // Hitta den senaste matchen (baserat på tiden)
  const lastMatch = completedMatches.reduce((latest, current) =>
    timeToMinutes(current.time) > timeToMinutes(latest.time) ? current : latest
  );

  return lastMatch.adjustedTime || 0; // Returnera delayTime för den senaste matchen
};

// Funktion för att uppdatera spelare i en match
export const applyPlayerUpdates = (match, updatedPlayers) => {
  const updatedMatch = { ...match };

  updatedPlayers.forEach((updatedPlayer) => {
    if (updatedPlayer) {
      const playerKey = findPlayerKeyById(updatedMatch, updatedPlayer.id);
      if (playerKey) {
        updatedMatch[playerKey] = {
          ...updatedMatch[playerKey],
          ...updatedPlayer,
        };
      } else {
        console.error("Player ID not found in match:", updatedPlayer.id);
      }
    }
  });

  return updatedMatch;
};

// Hjälpfunktion för att hitta nyckeln för en spelare baserat på ID
export const findPlayerKeyById = (match, playerId) => {
  if (match.player1.id === playerId) return "player1";
  if (match.player2.id === playerId) return "player2";
  return null;
};
