import React, { useState } from "react";
import { auth } from "../../firebase-config";
import { confirmPasswordReset } from "firebase/auth";
import { Input } from "../ui/Input";
import { Button } from "../ui/Button";
import AlertBox from "../ui/AlertBox";
import { useSearchParams, useNavigate } from "react-router-dom";
import { MaxWidthWrapper } from "../ui/MaxWidth";
import { ForgotPasswordWrapper } from "./ForgotPassword";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    const oobCode = searchParams.get("oobCode"); // Hämta koden från URL:en
    if (!oobCode) {
      setError("Ogiltig eller saknad återställningskod.");
      return;
    }

    try {
      await confirmPasswordReset(auth, oobCode, newPassword);
      setSuccess("Ditt lösenord har återställts!");
      setError("");
      setTimeout(() => {
        navigate("/login"); // Omdirigera till inloggningssidan
      }, 3000);
    } catch (err) {
      setError("Ett fel uppstod vid återställningen.");
      setSuccess("");
    }
  };

  return (
    <MaxWidthWrapper>
      <ForgotPasswordWrapper>
        <h2>Återställ lösenord</h2>
        <form onSubmit={handleResetPassword}>
          <Input
            type="password"
            placeholder="Ange nytt lösenord"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <Button type="submit">Spara nytt lösenord</Button>
        </form>
        {error && <AlertBox variant="danger">{error}</AlertBox>}
        {success && <AlertBox variant="success">{success}</AlertBox>}
      </ForgotPasswordWrapper>
    </MaxWidthWrapper>
  );
};

export default ResetPassword;
