import React, { useState } from "react";
import styled from "styled-components";
import DeleteIcon from "../../ui/FontIcons/DeleteIcon";
import PenIcon from "../../ui/FontIcons/PenIcon";
import { useCategory } from "../CategoryContext";
import PlayerEditForm from "./PlayerEditForm";

const PlayerListWrapper = styled.div`
  margin-bottom: 2rem;
  .children-wrapper {
    text-align: right;
    > button {
      display: inline-block;
    }
  }
`;

const PlayerList = styled.ul`
  list-style: none;
  padding: 0;

  li {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #eee;
    padding: 1rem 0;
    div {
      display: flex;
      align-items: center;
      width: 100%;
      > span {
        flex-grow: 1;
      }
      button {
        background: none;
        border: none;
        cursor: pointer;
        &:last-child {
          margin-left: 5px;
        }
      }
      svg {
        font-size: 1.2rem;
      }
    }
    form {
      margin-top: 1rem;
    }
  }
`;

const PlayerRow = ({ player, removePlayer, locked, isAdmin }) => {
  const [editingPlayer, setEditingPlayer] = useState(false);
  return (
    <li key={player.id}>
      <div>
        <span>
          {player.name} {isAdmin && <>({player.ranking})</>}
        </span>
        {isAdmin && (
          <>
            <button
              disabled={locked}
              className="secondary small"
              onClick={() => setEditingPlayer(!editingPlayer)}
            >
              {editingPlayer ? "Stäng" : <PenIcon />}
            </button>
            <button
              disabled={locked}
              className="secondary small"
              onClick={() => removePlayer(player.id)}
            >
              <DeleteIcon />
            </button>
          </>
        )}
      </div>
      {editingPlayer && isAdmin && (
        <PlayerEditForm
          player={player}
          closeEditForm={() => setEditingPlayer(false)}
        />
      )}
    </li>
  );
};

const PlayerListView = ({ children, locked }) => {
  const { removePlayer, players, isAdmin } = useCategory();
  return (
    <PlayerListWrapper>
      <PlayerList>
        {players &&
          players.map((player) => (
            <PlayerRow
              player={player}
              key={player.name}
              removePlayer={removePlayer}
              locked={locked}
              isAdmin={isAdmin}
            />
          ))}
      </PlayerList>
      <div className="children-wrapper">{children}</div>
    </PlayerListWrapper>
  );
};

export default PlayerListView;
