import React from "react";
import { auth } from "../../firebase-config";
import { signOut } from "firebase/auth";

const Logout = () => {
  const handleLogout = async () => {
    await signOut(auth);
    alert("Du har loggats ut!");
  };

  return <button onClick={handleLogout}>Logga ut</button>;
};

export default Logout;
