import { useState } from "react";
import { useCategory } from "../CategoryContext";
import { Input } from "../../ui/Input";
import { Button } from "../../ui/Button";
import styled from "styled-components";
import DeleteIcon from "../../ui/FontIcons/DeleteIcon";

const Container = styled.div``;

const EmailInputContainer = styled.form`
  display: flex;
  gap: 0.5rem;
`;

const ReporterList = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin: 0 0 1rem;
`;

const ReporterCard = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  .delete-email {
    cursor: pointer;
  }
`;

const ReporterName = styled.span`
  font-weight: bold;
  font-size: 0.9rem;
`;

const ErrorText = styled.p`
  color: red;
  margin-top: 0.5rem;
`;

const Reporters = () => {
  const { category, saveCategory } = useCategory();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const addReporterByEmail = (e) => {
    e.preventDefault();

    if (!email.trim()) {
      setError("E-postadressen får inte vara tom.");
      return;
    }

    // Kontrollera om e-post redan finns
    if (category.reporters?.includes(email.trim())) {
      setError("Denna e-postadress är redan tillagd som rapportör.");
      return;
    }

    const updatedCategory = {
      ...category,
      reporters: [...(category.reporters || []), email.trim().toLowerCase()],
    };

    saveCategory(updatedCategory);
    setEmail("");
    setError("");
  };

  const removeReporter = (reporterEmail) => {
    const updatedCategory = {
      ...category,
      reporters: category.reporters?.filter((email) => email !== reporterEmail),
    };
    saveCategory(updatedCategory);
  };

  const hasReporters = category.reporters && category.reporters.length > 0;

  return (
    <Container>
      {hasReporters && (
        <ReporterList>
          {category.reporters.map((reporterEmail) => (
            <ReporterCard key={reporterEmail}>
              <ReporterName>{reporterEmail}</ReporterName>
              <button
                className="delete-email"
                onClick={() => removeReporter(reporterEmail)}
              >
                <DeleteIcon />
              </button>
            </ReporterCard>
          ))}
        </ReporterList>
      )}
      <EmailInputContainer onSubmit={addReporterByEmail}>
        <Input
          type="email"
          placeholder="Ange användarens e-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button type="submit">Lägg till</Button>
      </EmailInputContainer>
      {error && <ErrorText>{error}</ErrorText>}
    </Container>
  );
};

export default Reporters;
