import styled from "styled-components";

export const MaxWidthWrapper = styled.div`
  max-width: 700px;
  margin: 0 auto;
  padding: 2rem 1rem 3rem;
`;

export const MaxWidth = styled.div`
  max-width: 700px;
  margin: 0 auto;
`;

export const NoWidthWrapper = styled.div`
  padding: 2rem 1rem 3rem;
`;
