import React, { useState } from "react";
import { Button } from "../../ui/Button";
import { useCategory } from "../CategoryContext";
import { FormWrapper } from "./PlayerForm";

const PlayerEditForm = ({ player, closeEditForm }) => {
  const [name, setName] = useState(player.name);
  const [ranking, setRanking] = useState(String(player.ranking));
  const { updatePlayer } = useCategory();
  const handleSubmit = (e) => {
    e.preventDefault();
    updatePlayer(player.id, name, parseInt(ranking));
    closeEditForm();
  };

  return (
    <FormWrapper
      className="player-edit-form player-form"
      onSubmit={handleSubmit}
    >
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Spelare"
      />
      <input
        type="number"
        value={ranking}
        onChange={(e) => setRanking(e.target.value)}
        placeholder="Ranking"
      />
      <Button type="submit">Spara</Button>
    </FormWrapper>
  );
};

export default PlayerEditForm;
