import { GlobalStyles } from "./theme";
import Header from "./components/Header";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  Outlet,
} from "react-router-dom";
import Players from "./components/Category/Players";
import Settings from "./components/Category/Settings/Settings";
import Results from "./components/Category/Results/Results";
import Knockouts from "./components/Category/Knockouts/Knockouts";
import CategoryStartPage from "./components/Category/CategoryStartPage";
import Category from "./components/Category/Category";
import Register from "./components/Auth/Register";
import Login from "./components/Auth/Login";
import Logout from "./components/Auth/Logout";
import MyPages from "./components/Auth/MyPages";
import ForgotPassword from "./components/Auth/ForgotPassword";
import ResetPassword from "./components/Auth/ResetPassword";
import CreateCategory from "./components/Category/CreateCategory";
import { AuthProvider } from "./components/Auth/AuthContext";
import MyCategories from "./components/Category/MyCategories";
import Group from "./components/Category/Groups/Group/Group";
import ResultItem from "./components/Category/Results/ResultItem";
import Groups from "./components/Category/Groups/Groups";
import Knockout from "./components/Category/Knockouts/Knockout/Knockout";
import LiveMatch from "./components/Category/Match/LiveMatch";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route path="/" element={<CategoryStartPage />}>
        <Route path="/create-category" element={<CreateCategory />} />
        <Route path="/my-categories" element={<MyCategories />} />
      </Route>
      <Route path="category/:categoryId" element={<Category />}>
        <Route path="spelare" element={<Players />} />
        <Route path="installning" element={<Settings />} />
        <Route path="pooler" element={<Groups />} />
        <Route path="pooler/:itemId" element={<Group />}>
          <Route path="match/:matchId" element={<LiveMatch />} />
        </Route>
        <Route path="slutspel" element={<Knockouts />} />
        <Route path="slutspel/:itemId" element={<Knockout />}>
          <Route path="match/:matchId" element={<LiveMatch />} />
        </Route>
        <Route path="resultat" element={<Results />} />
        <Route path="resultat/:itemId" element={<ResultItem />} />
      </Route>
      <Route path="/register" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/my-pages" element={<MyPages />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
    </Route>
  ),
  {
    future: {
      v7_startTransition: true, // Aktiverar React Router Future Flag för v7
    },
  }
);

function Layout() {
  return (
    <AuthProvider>
      <GlobalStyles />
      <div className="main-app-wrapper">
        <Header />
        <Outlet />
      </div>
    </AuthProvider>
  );
}

function App() {
  return <RouterProvider router={router} />;
}

export default App;
