import AlertBox from "../../ui/AlertBox";
import { MaxWidthWrapper } from "../../ui/MaxWidth";
import { useCategory } from "../CategoryContext";
import { hasKnockoutInCategory } from "../Common/helpers";
import ResultList from "./ResultList";

const ResultRoute = () => {
  const { category, groups, knockouts, resultReady } = useCategory();

  if (!resultReady)
    return (
      <MaxWidthWrapper>
        <AlertBox variant="info">
          <p>Turneringen är ännu inte färdigspelad</p>
        </AlertBox>
      </MaxWidthWrapper>
    );
  const hasKnockout = hasKnockoutInCategory(category.type);
  const items = hasKnockout ? knockouts : groups;
  const title = hasKnockout ? "Resultat" : "Resultat storpool";
  return <ResultList items={items} title={title} />;
};

export default ResultRoute;
