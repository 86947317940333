import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Auth/AuthContext";
import { useCategory } from "../../CategoryContext";

/**
 * Hook för att kontrollera om användaren är administratör för en kategori och omdirigera vid behov.
 * @param {string} redirectUrl - URL att omdirigera till om användaren inte är admin.
 */
const useAdminRedirect = (redirectUrl = "/login") => {
  const navigate = useNavigate();
  const { user, loading: authLoading } = useAuth(); // Kontrollera om användaren är inloggad
  const { isAdmin, category } = useCategory(); // Kontrollera om användaren är admin för kategorin

  useEffect(() => {
    if (authLoading || !category) return; // Vänta tills data är laddad

    if (!user || !isAdmin) {
      navigate(redirectUrl); // Omdirigera om användaren inte är inloggad eller admin
    }
  }, [user, isAdmin, authLoading, category, navigate, redirectUrl]);
};

export default useAdminRedirect;
