import React from "react";
import AlertBox from "./AlertBox";
import { Button } from "./Button";
import styled from "styled-components";
import { MaxWidthWrapper } from "./MaxWidth";
import { useCategory } from "../Category/CategoryContext";

const LockMessageWrapper = styled.div`
  margin-bottom: 2rem;
  button {
    width: 100%;
  }
  .first-name {
    text-transform: capitalize;
  }
`;

export const PlayerLockMessage = ({ name }) => {
  return (
    <MaxWidthWrapper>
      <AlertBox variant="warning">
        <p>Spelarlistan är inte låst. Gör detta innan du genererar {name}.</p>
      </AlertBox>
    </MaxWidthWrapper>
  );
};

const LockMessage = ({
  stageIsLocked,
  lockStage,
  laterStageOngoing,
  name,
  nextName,
  lockText,
  readyText,
  laterStageText,
}) => {
  return (
    <LockMessageWrapper>
      {stageIsLocked ? (
        <AlertBox variant="warning">
          <p>
            {lockText || (
              <>
                <span className="first-name">{name}</span> är nu låst. Välj att
                låsa upp för att redigera {name}.
              </>
            )}
          </p>
          {laterStageOngoing && (
            <p>
              {laterStageText || (
                <>
                  <strong>Obs! {nextName} är nu igång.</strong> Om du förändrar{" "}
                  {name} kommer {nextName} behöva skapas på nytt.
                </>
              )}
            </p>
          )}
          <Button
            className={"secondary"}
            onClick={() => lockStage(!stageIsLocked)}
          >
            Lås upp {name}
          </Button>
        </AlertBox>
      ) : (
        <AlertBox>
          <p>
            {readyText || (
              <>
                När du är klar med {name} väljer du att låsa {name} för att
                kunna gå vidare till nästa steg.
              </>
            )}
          </p>
          <Button onClick={() => lockStage(!stageIsLocked)}>Lås {name}</Button>
        </AlertBox>
      )}
    </LockMessageWrapper>
  );
};

export const KnockoutLockMessage = () => {
  const { knockoutIsLocked, lockKnockout, knockoutStageCompleted } =
    useCategory();
  return (
    <>
      {knockoutStageCompleted ? (
        <LockMessage
          stageIsLocked={knockoutIsLocked}
          lockStage={lockKnockout}
          name="slutspelet"
          lockText="Slutspelet är låst. Välj att låsa upp för att redigera slutspelet. Turneringen kommer att bli aktiv igen."
          readyText="Nu kan du låsa slutspelet och därmed avsluta turneringen."
        />
      ) : (
        <AlertBox variant="info">
          <p>
            När alla matcher är färdigspelade kommer du kunna låsa slutspelet
            och därmed avsluta turneringen.
          </p>
        </AlertBox>
      )}
    </>
  );
};

export default LockMessage;
