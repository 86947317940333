import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase-config";
import { updateProfile, updatePassword } from "firebase/auth";
import { Input } from "../ui/Input";
import { Button } from "../ui/Button";
import AlertBox from "../ui/AlertBox";
import { MaxWidthWrapper } from "../ui/MaxWidth";
import styled from "styled-components";
import { ContestPageSubTitle } from "../Category/CategoryStartPage";

const MyPagesWrapper = styled.div`
  max-width: 400px;
  margin: 0 auto;
  h1 {
    text-align: center;
    margin-bottom: 1rem;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 3rem;
    button {
      width: 100%;
    }
  }

  .button-group {
    display: flex;
    justify-content: space-between;
  }
`;

const MyPages = () => {
  const navigate = useNavigate();
  const user = auth.currentUser;

  const [displayName, setDisplayName] = useState(user?.displayName || "");
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (!user) {
      navigate("/login"); // Omdirigera till login om användaren inte är inloggad
    }
  }, [user, navigate]);

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    try {
      await updateProfile(user, { displayName });
      setMessage({ type: "success", text: "Profilen har uppdaterats!" });
    } catch (err) {
      setMessage({ type: "danger", text: err.message });
    }
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    try {
      await updatePassword(user, newPassword);
      setMessage({ type: "success", text: "Lösenordet har uppdaterats!" });
      setNewPassword("");
    } catch (err) {
      setMessage({ type: "danger", text: err.message });
    }
  };

  return (
    <MaxWidthWrapper>
      <MyPagesWrapper>
        <h1>Mina Sidor</h1>
        <ContestPageSubTitle>E-mail</ContestPageSubTitle>
        <p>{user.email}</p>
        <ContestPageSubTitle>Namn</ContestPageSubTitle>
        <form onSubmit={handleUpdateProfile}>
          <Input
            type="text"
            placeholder="Namn"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            required
          />
          <div className="button-group">
            <Button type="submit">Uppdatera namn</Button>
          </div>
        </form>
        <ContestPageSubTitle>Lösenord</ContestPageSubTitle>
        <form onSubmit={handleUpdatePassword}>
          <Input
            type="password"
            placeholder="Nytt lösenord"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <div className="button-group">
            <Button type="submit">Uppdatera Lösenord</Button>
          </div>
        </form>
        {message && <AlertBox variant={message.type}>{message.text}</AlertBox>}
      </MyPagesWrapper>
    </MaxWidthWrapper>
  );
};

export default MyPages;
