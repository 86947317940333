import React from "react";
import styled from "styled-components";
import { Button } from "../ui/Button";
import PlusIcon from "../ui/FontIcons/PlusIcon";
import { useCategoryList } from "./CategoryListContext";
import { Link } from "react-router-dom";
import { isPrivate } from "../util/helpers";
import { theme } from "../../theme";
import { useAuth } from "../Auth/AuthContext";

const CategoryListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
  padding: 1rem 1rem 2rem 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 0 auto;
  background-color: #f9f9f9;

  .categories {
    margin-bottom: 1rem;
    .category-item {
      display: flex;
      justify-content: space-between;
      padding: 0.5rem 0;
      cursor: pointer;
      transition: background-color 0.3s ease;
      text-align: center;
      border-top: 1px solid #ddd;
      ${theme.media.sm} {
        font-size: 0.9rem;
      }
      &:last-child {
        border-bottom: 1px solid #ddd;
      }
      .name {
        display: flex;
        align-items: center;
        gap: 5px;
      }
      .private {
        color: #aaa;
        font-size: 0.7rem;
        text-transform: uppercase;
      }
    }
  }
  button {
    margin-top: 2rem;
  }
  .empty-message {
    text-align: center;
    font-size: 0.9rem;
    color: #888;
  }
`;

const CategorySection = ({ title, categories }) => (
  <div>
    <h3>{title}</h3>
    {categories.length > 0 ? (
      <div className="categories">
        {categories.map((category) => (
          <div className="category-item" key={category.id}>
            <span className="name">
              <Link key={category.id} to={`/category/${category.id}`}>
                <span>{category.name} </span>
              </Link>
              {isPrivate(category) && <span className="private">(Privat)</span>}
            </span>
            <span>{category.date}</span>
          </div>
        ))}
      </div>
    ) : (
      <div className="empty-message">Inget att visa här.</div>
    )}
  </div>
);

const getStatus = (category) => {
  const today = new Date();
  const categoryDate = new Date(category.date);

  const isSameDate =
    today.getFullYear() === categoryDate.getFullYear() &&
    today.getMonth() === categoryDate.getMonth() &&
    today.getDate() === categoryDate.getDate();

  if (
    category.lock.knockouts ||
    (category.type === "round-robin" && category.lock.groups)
  ) {
    return "completed";
  }

  if (isSameDate && category.lock.players) {
    return "ongoing";
  }

  if (categoryDate < today) {
    return "completed";
  }

  return "upcoming";
};

const CategoryList = ({ title = "Turneringar", myCategories = false }) => {
  const { categories } = useCategoryList();
  const { user } = useAuth();

  const filteredCategories = myCategories
    ? categories.filter((category) => category.owner.id === user.uid)
    : categories;

  const ongoingCategories = filteredCategories.filter(
    (category) => getStatus(category) === "ongoing"
  );
  const upcomingCategories = filteredCategories.filter(
    (category) => getStatus(category) === "upcoming"
  );
  const completedCategories = filteredCategories.filter(
    (category) => getStatus(category) === "completed"
  );

  return (
    <>
      <CategoryListWrapper>
        <h2 className="header">{title}</h2>
        <CategorySection title="Pågående" categories={ongoingCategories} />
        <CategorySection title="Kommande" categories={upcomingCategories} />
        <CategorySection title="Avslutade" categories={completedCategories} />
        <Button type="button" className="add-new">
          <Link to="/create-category">
            <PlusIcon /> Skapa turnering
          </Link>
        </Button>
      </CategoryListWrapper>
    </>
  );
};

export default CategoryList;
