// hooks/useAuthRedirect.js
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";

/**
 * Custom hook för att kontrollera om användaren är inloggad och omdirigera vid behov.
 * @param {string} redirectUrl - URL att omdirigera till om användaren inte är inloggad.
 */
const useAuthRedirect = (redirectUrl = "/login") => {
  const navigate = useNavigate();
  const { user, loading } = useAuth();

  useEffect(() => {
    if (!loading && !user) {
      navigate(redirectUrl);
    }
  }, [navigate, redirectUrl, user, loading]);
};

export default useAuthRedirect;
