import React from "react";
import styled, { css } from "styled-components";

const AlertWrapper = styled.div`
  padding: 1rem;
  border-radius: 8px;
  font-size: 1rem;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .message {
    width: 100%;
  }
  p {
    margin-top: 0;
    &:last-child {
      margin-bottom: 0;
    }
  }

  ${(props) =>
    props.variant === "danger" &&
    css`
      background-color: #f8d7da;
      color: #842029;
      border: 1px solid #f5c2c7;
    `}

  ${(props) =>
    props.variant === "warning" &&
    css`
      background-color: #fff3cd;
      color: #664d03;
      border: 1px solid #ffecb5;
    `}

  ${(props) =>
    props.variant === "info" &&
    css`
      background-color: #cff4fc;
      color: #055160;
      border: 1px solid #b6effb;
    `}

    ${(props) =>
    props.variant === "success" &&
    css`
      background-color: #c7ffb5;
      color: #22471e;
      border: 1px solid #bdf1a9;
    `}
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.2rem;
  color: inherit;
  cursor: pointer;
  margin-left: 1rem;
  padding: 0;

  &:hover {
    opacity: 0.8;
  }
`;

const AlertBox = ({ variant = "info", children, onClose }) => {
  return (
    <AlertWrapper className="alert-box" variant={variant}>
      <div className="message">{children}</div>
      {onClose && <CloseButton onClick={onClose}>&times;</CloseButton>}
    </AlertWrapper>
  );
};

export default AlertBox;
