import { ReactComponent as Logo } from "./ui/icons/table-tennis-paddle-ball-duotone.svg";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import UserIcon from "./ui/FontIcons/UserIcon";
import { auth } from "../firebase-config";
import { useRef, useState } from "react";
import useClickOutside from "./ui/hooks/useClickOutside";
import { theme } from "../theme";

const StyledHeader = styled.header`
  height: 50px;
  background: var(--color-primary-dark);
  padding: 0 1rem;
  .inner {
    max-width: var(--max-width);
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .logo {
    display: flex;
    align-items: center;
    svg {
      max-width: 25px;
      margin-right: 10px;
      .fa-primary {
        fill: var(--color-white);
      }
      .fa-secondary {
        fill: var(--color-primary);
      }
    }
    h1 {
      margin: 0;
      text-transform: uppercase;
      font-size: 1.2rem;
      a {
        color: var(--color-white);
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .right-side {
    position: absolute;
    right: 1rem;
    .user-icon {
      position: relative;
      svg {
        max-width: 30px;
        color: var(--color-white);
        cursor: pointer;
        transition: transform 0.2s ease;
        &:hover {
          transform: scale(1.1);
        }
      }
      &.loggedin {
        svg {
          color: var(--color-primary-light);
        }
      }
    }
    .dropdown {
      position: absolute;
      top: 34px;
      right: 0;
      width: 170px;
      background: white;
      border: 1px solid #ccc;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      padding: 1rem 0.5rem;
      z-index: 9999;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      h4 {
        margin: 0.5rem 0 1rem 0;
      }
      a,
      button {
        background: none;
        border: none;
        cursor: pointer;
        text-decoration: underline;
        font-size: 0.9rem;
        padding: 0;
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }
  }
  @media (orientation: landscape) {
    ${theme.media.mdDown} {
      height: 36px;
      .logo {
        h1 {
          font-size: 0.9rem;
        }
      }
    }
  }
`;

const Header = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const user = auth.currentUser;
  const navigate = useNavigate();

  useClickOutside(dropdownRef, () => setDropdownOpen(false));

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate("/");
      setDropdownOpen(false);
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  return (
    <StyledHeader id="main-header">
      <div className="inner">
        <div className="logo">
          <Logo />
          <h1>
            <Link to="/">Pingistävling.se</Link>
          </h1>
        </div>
        <div className="right-side">
          <div
            className={`user-icon ${user ? "loggedin" : ""}`}
            onClick={() => setDropdownOpen(!isDropdownOpen)}
          >
            <UserIcon />
          </div>
          {isDropdownOpen && (
            <div className="dropdown" ref={dropdownRef}>
              {user ? (
                <>
                  <h4>{user.displayName || "Användare"}</h4>
                  <Link to="/my-pages" onClick={() => setDropdownOpen(false)}>
                    Mina sidor
                  </Link>
                  <Link
                    to="/my-categories"
                    onClick={() => setDropdownOpen(false)}
                  >
                    Mina turneringar
                  </Link>
                  <button onClick={handleLogout}>Logga ut</button>
                </>
              ) : (
                <>
                  <Link to="/login" onClick={() => setDropdownOpen(false)}>
                    Logga in
                  </Link>
                  <Link to="/register" onClick={() => setDropdownOpen(false)}>
                    Registrera
                  </Link>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </StyledHeader>
  );
};

export default Header;
