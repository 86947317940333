import styled from "styled-components";
import CategoryList from "./CategoryList";
import useAuthRedirect from "../Auth/hooks/useAuthRedirect";

export const MyCategoriesWrapper = styled.div``;

const MyCategories = () => {
  useAuthRedirect();
  return (
    <MyCategoriesWrapper>
      <CategoryList title="Mina turneringar" myCategories={true} />
    </MyCategoriesWrapper>
  );
};

export default MyCategories;
