import React, { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  useCategoryItem,
  isKnockout,
} from "../CategoryItem/CategoryItemContext";

const MatchContext = createContext();

export const getMatchFromGroup = (matchId, group) => {
  return group.rounds
    .flatMap((round) => round)
    .find((m) => {
      return m.matchId === parseInt(matchId, 10);
    });
};

export const findMatchIndex = (matchId, rounds) => {
  for (let roundIndex = 0; roundIndex < rounds.length; roundIndex++) {
    const round = rounds[roundIndex];
    const matchIndex = round.findIndex(
      (m) => m.matchId === parseInt(matchId, 10)
    );

    if (matchIndex !== -1) {
      return { roundIndex, matchIndex };
    }
  }
  return null;
};

export const MatchProvider = ({ children }) => {
  const { matchId } = useParams();
  const [match, setMatch] = useState(null);
  const { item: group } = useCategoryItem();

  useEffect(() => {
    if (!group || !group.rounds || !matchId) {
      setMatch(null);
      return;
    }
    const foundMatch = getMatchFromGroup(matchId, group);
    setMatch(foundMatch || null);
  }, [group, matchId]);

  const contextValue = {
    match,
  };

  return (
    <MatchContext.Provider value={contextValue}>
      {children}
    </MatchContext.Provider>
  );
};

export const useMatchItem = () => {
  return useContext(MatchContext);
};
