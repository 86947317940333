import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../../ui/Button";
import { useCategory } from "../CategoryContext";
import { useCategoryItem } from "../CategoryItem/CategoryItemContext";
import { Player } from "./Player";

const MatchWrapper = styled.div`
  margin: 0;
  padding: 5px 1rem;
  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
  > h5 {
    margin: 5px 0 15px 0;
    font-weight: var(--font-weight-light);
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .player-result {
    display: flex;
    width: 100%;
    > a {
      text-decoration: none;
    }
  }
  .players {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 1rem;
    flex-grow: 1;
  }
  .result-wrapper {
    padding: 0;
    margin-left: 0.5rem;
    cursor: pointer;
    align-items: center;
    height: 100%;
    &.numbers {
      width: 9px;
    }
    .result {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      height: 100%;
      &.completed {
        font-weight: var(--font-weight-bold);
      }
      &.incomplete {
        color: #aaa;
      }
    }
    .report {
      margin: 10px 0;
      padding: 0 6px;
      height: 30px;
      font-size: 0.7rem;
    }
  }
`;

const MatchDelayWrapper = styled.span`
  font-size: 0.7rem;
  color: white;
  padding: 0 3px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  &.positive {
    background: var(--color-green);
  }
  &.negative {
    background: var(--color-red);
  }
`;

export const MatchDelay = ({ adjustedTime }) => {
  if (!(adjustedTime !== 0 && adjustedTime)) return null;
  return (
    <MatchDelayWrapper
      className={`${adjustedTime > 0 ? "negative" : "positive"} adjusted-time`}
    >
      {adjustedTime > 0 && "+"}
      {adjustedTime}min
    </MatchDelayWrapper>
  );
};

export const Match = ({ match, locked }) => {
  const {
    category: { set, matchReport },
    isReporter,
  } = useCategory();

  const { toggleReportResult, reportResults, updateResult } = useCategoryItem();

  const matchCompleted = match.winner;

  const reportResult = reportResults[match.matchId];

  const togglebutton =
    !match.result?.player1 &&
    !match.result?.player2 &&
    match.player1.name &&
    match.player2.name &&
    !reportResult;

  const matchStarted =
    match.player1.points &&
    match.player1.points.length > 0 &&
    match.player2.points &&
    match.player2.points.length > 0;

  const matchStartedSet =
    match.player1.set ||
    match.player1.set === 0 ||
    match.player2.set ||
    match.player2.set === 0;

  return (
    <MatchWrapper key={match.matchId} className="match">
      <span className="bracket" />
      <span className="connector" />
      <h5 className="match-title">
        <span>Match {match.matchId}</span>
        <span>{match.time || match.timeOrg}</span>
        <MatchDelay adjustedTime={match.adjustedTime} />
      </h5>
      <div className="player-result">
        <div className="players">
          <Player
            playerNo="player1"
            opponentNo="player2"
            match={match}
            reportResults={reportResults}
            updateResult={updateResult}
            set={set}
          />
          <Player
            playerNo="player2"
            opponentNo="player1"
            match={match}
            reportResults={reportResults}
            updateResult={updateResult}
            set={set}
          />
        </div>
        {matchReport !== "points" ? (
          <div>
            <div
              className={`result-wrapper ${
                togglebutton ? "button" : "numbers"
              }`}
              onClick={() =>
                !locked && isReporter && toggleReportResult(match.matchId)
              }
            >
              <div
                className={`result ${
                  matchCompleted ? "completed" : "incomplete"
                }`}
              >
                <span>{match.player1.set}</span>
                {togglebutton &&
                  isReporter &&
                  !matchCompleted &&
                  !matchStartedSet && (
                    <Button disabled={locked} className="report small">
                      <>Rapportera</>
                    </Button>
                  )}
                <span>{match.player2.set}</span>
              </div>
            </div>
          </div>
        ) : (
          <Link to={`match/${match.matchId}`}>
            <div className={`result-wrapper`}>
              <div
                className={`result ${
                  matchCompleted ? "completed" : "incomplete"
                }`}
              >
                <span>{match.player1.set}</span>
                {!matchStarted && !matchCompleted && isReporter && (
                  <Button disabled={locked} className="report small">
                    <>Gå till match</>
                  </Button>
                )}
                <span>{match.player2.set}</span>
              </div>
            </div>
          </Link>
        )}
      </div>
    </MatchWrapper>
  );
};
