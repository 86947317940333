// Flatten `rounds`
export const convertRoundsForFirestore = (rounds) => {
  return rounds
    ?.map((round, roundIndex) =>
      round.map((match, matchIndex) => ({
        roundIndex,
        matchIndex,
        match,
      }))
    )
    .flat();
};

// Restore `rounds`
export const restoreRoundsForFirestore = (rounds) => {
  return rounds?.reduce((acc, { roundIndex, matchIndex, match }) => {
    if (!acc[roundIndex]) acc[roundIndex] = [];
    acc[roundIndex][matchIndex] = match;
    return acc;
  }, []);
};
