import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const GroupTitleWrapper = styled.div`
  background: none;
  border: none;
  padding: 0.75rem 0;
  width: 100%;
  border-top: 1px solid #cdcdcd;
  border-bottom: 1px solid #c1c1c1;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  display: flex;
  > a {
  }
  h2 {
    margin: 0;
    font-size: 1.2rem;
  }
  span.status {
    text-decoration: none;
    font-size: 0.9rem;
    color: #aaa;
    display: flex;
    gap: 1rem;
  }
`;

export const GroupTitle = ({ title, children, id }) => {
  return (
    <>
      <GroupTitleWrapper>
        <Link to={`${id}`}>
          <h2>{title}</h2>
        </Link>
        {children}
      </GroupTitleWrapper>
    </>
  );
};
