import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase-config";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { Input } from "../ui/Input";
import { Button } from "../ui/Button";
import AlertBox from "../ui/AlertBox";
import { MaxWidthWrapper } from "../ui/MaxWidth";
import { LoginWrapper } from "./Login";

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState(null); // Hanterar både fel och framgångsmeddelanden
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      // Uppdatera användarens profil med namnet
      await updateProfile(userCredential.user, {
        displayName: name,
      });

      setMessage({ type: "success", text: "Registrering lyckades!" });

      // Dirigera användaren tillbaka till startsidan efter en liten fördröjning
      setTimeout(() => navigate("/"), 1000);
    } catch (err) {
      setMessage({ type: "danger", text: err.message });
    }
  };

  return (
    <MaxWidthWrapper>
      <LoginWrapper>
        <h2>Registrera dig</h2>
        <form onSubmit={handleRegister}>
          <Input
            type="text"
            placeholder="Namn"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <Input
            type="email"
            placeholder="E-post"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <Input
            type="password"
            placeholder="Lösenord"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <Button type="submit">Registrera</Button>
        </form>
        {message && <AlertBox variant={message.type}>{message.text}</AlertBox>}
      </LoginWrapper>
    </MaxWidthWrapper>
  );
};

export default Register;
