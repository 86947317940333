import { v4 as uuidv4 } from "uuid";

export function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function shuffle(array) {
  var i = array.length,
    j = 0,
    temp;

  while (i--) {
    j = Math.floor(Math.random() * (i + 1));

    // swap randomly chosen element with current element
    temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }

  return array;
}

export const scrollTo = (ref) => {
  if (ref) {
    ref.scrollIntoView({ behavior: "smooth", block: "start" });
  }
};

export function generateId() {
  return uuidv4();
}

export function isPrivate(category) {
  return category && category.visibility === "private";
}

export const getCurrentDate = () => {
  const today = new Date();
  return today.toISOString().split("T")[0];
};

export const getCurrentTime = () => {
  const now = new Date();
  return now.toTimeString().slice(0, 5);
};

export const timeToMinutes = (time) => {
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
};

export const minutesToTime = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours.toString().padStart(2, "0")}:${mins
    .toString()
    .padStart(2, "0")}`;
};

export const indexToChar = (index) => {
  if (index < 0) {
    throw new Error("Index must be non-negative");
  }
  return String.fromCharCode("A".charCodeAt(0) + index);
};
