import React from "react";
import styled from "styled-components";
import { Button } from "../../ui/Button";
import Knockout from "./Knockout/Knockout";
import { MaxWidth, MaxWidthWrapper } from "../../ui/MaxWidth";
import { useCategory } from "../CategoryContext";
import { KnockoutLockMessage, PlayerLockMessage } from "../../ui/LockMessage";
import { ContestPageTitle } from "../CategoryStartPage";
import AlertBox from "../../ui/AlertBox";
import { getCategoryItemStatus, isGroupKnockout } from "../Common/helpers";
import { GroupTitle } from "../../ui/GroupTitle";

const KnockoutWrapper = styled.div`
  padding-bottom: 3rem;
  text-align: left;
`;

export const Knockouts = () => {
  const {
    category,
    knockouts,
    generateKnockouts,
    playersIsLocked,
    isAdmin,
    isReporter,
    knockoutIsLocked,
    groupsIsLocked,
  } = useCategory();

  const isGroupKnockoutType = isGroupKnockout(category.type);
  const hasKnockouts = category.knockouts?.length > 0;

  if (!playersIsLocked) return <PlayerLockMessage name="slutspelet" />;

  if (isGroupKnockoutType && !groupsIsLocked && isAdmin)
    return (
      <MaxWidthWrapper>
        <AlertBox variant="warning">
          <p>
            Poolspelet är inte färdigspelat och låst. Gör detta innan du kan
            skapa slutspelet.
          </p>
        </AlertBox>
      </MaxWidthWrapper>
    );

  if (!hasKnockouts && isAdmin)
    return (
      <MaxWidthWrapper>
        <Button className="generate-schedule" onClick={generateKnockouts}>
          Skapa slutspelet
        </Button>
      </MaxWidthWrapper>
    );

  return (
    <MaxWidthWrapper>
      <KnockoutWrapper>
        {hasKnockouts ? (
          <>
            <MaxWidth>
              <ContestPageTitle>Slutspel</ContestPageTitle>
              {isAdmin && <KnockoutLockMessage />}
              {!isAdmin && isReporter && knockoutIsLocked && (
                <AlertBox variant="warning">
                  <p>
                    Slutspelet är nu färdigspelat och låst av administratören.
                  </p>
                </AlertBox>
              )}
            </MaxWidth>
            <Knockout />
            {knockouts.map((knockout) => (
              <GroupTitle
                key={knockout.id}
                id={knockout.id}
                title={knockout.name}
              >
                <span className="status">
                  {getCategoryItemStatus(knockout)}
                </span>
              </GroupTitle>
            ))}
          </>
        ) : (
          <MaxWidth>
            <AlertBox variant="info">
              <p>Slutspelet är inte lottat ännu av administratören.</p>
            </AlertBox>
          </MaxWidth>
        )}
      </KnockoutWrapper>
    </MaxWidthWrapper>
  );
};

export default Knockouts;
