import React from "react";
import styled from "styled-components";
import { MaxWidthWrapper } from "../../ui/MaxWidth";
import AlertBox from "../../ui/AlertBox";
import { useCategory } from "../CategoryContext";
import { useCategoryItem } from "../CategoryItem/CategoryItemContext";
import { calcKnockoutToplist } from "../Common/calcKnockoutToplist";

const KnockoutResultWrapper = styled.div`
  overflow: auto;
  > h2 {
    text-align: center;
  }
  .podium {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 300px;
    margin-top: 2rem;
    position: relative;
  }
  .podium .place {
    width: 100px;
    text-align: center;
    margin: 0 10px;
    padding: 20px 0;
    color: white;
    font-weight: bold;
    position: relative;
  }
  .podium .place .name {
    position: absolute;
    bottom: 100%;
    width: 100%;
    left: 0;
    right: 0;
    color: black;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    span {
      margin: 5px 0;
    }
  }
  .first {
    background-color: gold;
    height: 200px;
  }
  .second {
    background-color: silver;
    height: 150px;
  }
  .third {
    background-color: #cd7f32; /* Bronze color */
    height: 100px;
  }
  .rest-list {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    > div {
      margin: 5px 0;
      .pos {
        font-weight: bold;
      }
    }
  }
`;

const RestPlayer = ({ player, pos = "" }) => {
  if (!player) return null;
  return (
    <div>
      <span className="pos">{pos}</span>{" "}
      <span className="player">{player.name}</span>
    </div>
  );
};

const ResultKnockout = () => {
  const { knockoutIsLocked, isReporter } = useCategory();
  const { item: knockout } = useCategoryItem();

  if (!knockout) return null;

  if (!knockoutIsLocked)
    return (
      <MaxWidthWrapper>
        <AlertBox variant="info">
          <p>
            Slutspelet är ej färdigspelat
            {isReporter && <> och låst av administratören</>}.
          </p>
        </AlertBox>
      </MaxWidthWrapper>
    );

  const toplist = calcKnockoutToplist(knockout);

  return (
    <KnockoutResultWrapper>
      <div className="podium">
        {toplist.length > 1 && (
          <div className="place second">
            <div className="name">
              <span>{toplist[1].name}</span>
            </div>
            2a
          </div>
        )}
        <div className="place first">
          <div className="name">
            <span>{toplist[0].name}</span>
          </div>
          1a
        </div>
        {toplist.length > 3 && (
          <div className="place third">
            <div className="name">
              <span>{toplist[2].name}</span>
            </div>
            3a
          </div>
        )}
      </div>
      <div className="rest-list">
        <RestPlayer player={toplist[3]} pos="4." />
        <RestPlayer player={toplist[4]} pos="5." />
        <RestPlayer player={toplist[5]} pos="6." />
        <RestPlayer player={toplist[6]} pos="7." />
        <RestPlayer player={toplist[7]} pos="8." />
      </div>
    </KnockoutResultWrapper>
  );
};

export default ResultKnockout;
