import React from "react";
import styled from "styled-components";
import { theme } from "../../theme";
import { Link } from "react-router-dom";
import { useCategory } from "./CategoryContext";

const Wrapper = styled.div`
  .tournament-sticky {
    width: 100%;
    padding: 1rem;
    box-shadow: 1px 1px 1px #000;
    background: var(--color-primary-medium2-light);
    padding: 7px;
    box-shadow: -1px 0px 4px #00000087;

    .inner {
      max-width: 800px;
      margin: 0 auto;
      width: 100%;
      display: flex;
      align-items: center;
      text-align: center;
      .name {
        &,
        a {
          color: white;
          text-decoration: none;
        }
        flex-grow: 1;
        margin: 0;
        padding: 0;
      }
    }
  }
  .tournament-form {
  }
  .hide-sm {
    ${theme.media.sm} {
      display: none;
    }
  }
  @media (orientation: landscape) {
    ${theme.media.mdDown} {
      .tournament-sticky {
        .inner {
          .name {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
`;

const CategoryHeader = () => {
  const { category } = useCategory();
  return (
    <Wrapper>
      <form className="tournament-form">
        <div className="tournament-sticky">
          <div className="inner">
            {category && (
              <h3 className="name">
                <Link to={`/category/${category.id}`}>{category.name}</Link>
              </h3>
            )}
          </div>
        </div>
      </form>
    </Wrapper>
  );
};

export default CategoryHeader;
