import React, { createContext, useContext, useState, useEffect } from "react";
import { useParams } from "react-router";
import {
  listenToCategoryItem,
  updateCategoryItem,
} from "../../Database/categoryItemService";
import {
  convertRoundsForFirestore,
  restoreRoundsForFirestore,
} from "../../Database/helpers";
import { useCategory } from "../CategoryContext";
import { adjustPoolMatchTimes } from "../Common/adjustPoolTimes";
import { getNumberOfTables } from "../Common/generateGroupSchedule";
import { updateKnockoutTree } from "../Common/generateKnockoutTree";
import { applyPlayerUpdates, hasKnockoutInCategory } from "../Common/helpers";
import { findMatchIndex } from "../Match/MatchContext";

// Skapa generisk kontext
const CategoryItemContext = createContext();

// Provider-komponent
export const CategoryItemProvider = ({ children, itemType }) => {
  const { itemId } = useParams(); // `itemId` används för både `groupId` och `knockoutId`
  const [item, setItem] = useState(null);
  const [reportResults, setReportResults] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { category, clearKnockouts } = useCategory();
  const categoryId = category.id;
  const isKnockout = itemType === "knockouts";

  const toggleReportResult = (matchId) => {
    setReportResults((prev) => ({
      ...prev,
      [matchId]: !prev[matchId],
    }));
  };

  const saveItem = async (updatedItem) => {
    try {
      await updateCategoryItem(
        category.id,
        {
          ...updatedItem,
          rounds: convertRoundsForFirestore(updatedItem.rounds),
        },
        itemType
      );
      setItem(updatedItem);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    if (!categoryId || !itemId) return;

    const unsubscribe = listenToCategoryItem(
      categoryId,
      itemId,
      itemType,
      (fetchedItem) => {
        if (fetchedItem) {
          setItem({
            ...fetchedItem,
            rounds: restoreRoundsForFirestore(fetchedItem.rounds),
          });
          setError(null);
        } else {
          setError(`${itemType.slice(0, -1)} not found`);
        }
        setLoading(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [categoryId, itemId, itemType]);

  const updateResult = (matchId, updatedPlayer1, updatedPlayer2) => {
    const updatedItem = { ...item };

    const targetMatchIndex = findMatchIndex(matchId, updatedItem.rounds);

    if (!targetMatchIndex) {
      console.error("Match not found:", matchId);
      return null;
    }

    const { roundIndex, matchIndex } = targetMatchIndex;
    const targetMatch = updatedItem.rounds[roundIndex][matchIndex];

    const updatedMatch = applyPlayerUpdates(targetMatch, [
      updatedPlayer1,
      updatedPlayer2,
    ]);

    if (
      updatedPlayer1.set === category.set &&
      (updatedPlayer2.set || updatedPlayer2.set === 0)
    ) {
      updatedMatch.winner = updatedPlayer1;
    } else if (
      updatedPlayer2.set === category.set &&
      (updatedPlayer1.set || updatedPlayer1.set === 0)
    ) {
      updatedMatch.winner = updatedPlayer2;
    } else {
      updatedMatch.winner = false;
    }

    updatedItem.rounds[roundIndex][matchIndex] = updatedMatch;

    if (hasKnockoutInCategory(category.type)) {
      if (isKnockout) {
        updatedItem.rounds = updateKnockoutTree(updatedItem.rounds);
      } else {
        clearKnockouts(true);
      }
    }

    if (updatedMatch.winner) {
      if (!isKnockout && updatedMatch.timeOrg) {
        const tables = getNumberOfTables(category.type);
        const matchTimeToAdd = parseInt(category.matchTime, 10) / tables;

        updatedItem.rounds = adjustPoolMatchTimes(
          updatedItem.rounds,
          matchId,
          parseInt(matchTimeToAdd, 10)
        );
      }

      const updatedResults = { ...reportResults };
      updatedResults[updatedMatch.matchId] = false;
      setReportResults(updatedResults);
    }
    saveItem(updatedItem);
  };

  const contextValue = {
    item,
    isKnockout,
    updateResult,
    reportResults,
    toggleReportResult,
    loading,
    error,
  };

  return (
    <CategoryItemContext.Provider value={contextValue}>
      {children}
    </CategoryItemContext.Provider>
  );
};

export const useCategoryItem = () => {
  return useContext(CategoryItemContext);
};
