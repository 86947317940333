export const calcKnockoutToplist = (category) => {
  if (!category?.rounds || category.rounds.length === 0) {
    console.warn("Insufficient data to calculate toplist");
    return [];
  }

  const isValidPlayer = (player) => player && player.name !== "bye";
  const toplist = [];

  // Final
  const finalRound = category.rounds[category.rounds.length - 1];
  const finalMatch = finalRound[0];
  const finalWinner = finalMatch.winner;
  const finalLoser =
    finalMatch.player1.id === finalWinner.id
      ? finalMatch.player2
      : finalMatch.player1;

  if (isValidPlayer(finalWinner)) toplist[0] = finalWinner; // 1:a
  if (isValidPlayer(finalLoser)) toplist[1] = finalLoser; // 2:a

  if (category.rounds.length < 2) {
    return toplist;
  }

  // Semifinals
  const semiFinalRound = category.rounds[category.rounds.length - 2];
  const semiFinalLosers = [];
  semiFinalRound.forEach((match) => {
    const loser =
      match.player1.id === match.winner.id ? match.player2 : match.player1;

    if (isValidPlayer(loser)) {
      if (match.winner.id === finalWinner.id) {
        toplist[2] = loser; // 3:a
      } else {
        toplist[3] = loser; // 4:a
      }
      semiFinalLosers.push({ loser, winnerId: match.winner.id });
    }
  });

  if (category.rounds.length < 3) {
    return toplist;
  }

  // Quarterfinals
  const quarterFinalRound = category.rounds[category.rounds.length - 3];
  const quarterFinalLosers = [];
  quarterFinalRound.forEach((match) => {
    const loser =
      match.player1.id === match.winner.id ? match.player2 : match.player1;

    if (isValidPlayer(loser)) {
      quarterFinalLosers.push({ loser, winnerId: match.winner.id });
    }
  });

  // Map quarterfinal losers to positions based on who they lost to in the semifinals
  const remainingPositions = [];
  quarterFinalLosers.forEach(({ loser, winnerId }) => {
    if (
      semiFinalLosers.some(
        (semi) => semi.winnerId === winnerId && semi.loser.id === toplist[2].id
      )
    ) {
      remainingPositions[0] = loser; // Förlorade mot semifinalförloraren som blev 3:a (5:a)
    } else if (
      semiFinalLosers.some(
        (semi) => semi.winnerId === winnerId && semi.loser.id === toplist[3].id
      )
    ) {
      remainingPositions[1] = loser; // Förlorade mot semifinalförloraren som blev 4:a (6:a)
    } else {
      // Fördela övriga förlorare (7:a och 8:a) baserat på matchningar i kvartsfinaler
      if (!remainingPositions[2]) remainingPositions[2] = loser; // 7:a
      else remainingPositions[3] = loser; // 8:a
    }
  });

  // Fyll toplistan med resterande positioner
  toplist.push(...remainingPositions.filter(isValidPlayer));

  // Kontrollera att toplistan har exakt 8 positioner
  while (toplist.length < 8) {
    toplist.push({ name: "-", id: "-" }); // Fyll med placeholder vid behov
  }

  return toplist.slice(0, 8); // Begränsa till exakt 8 platser
};
