import { MaxWidthWrapper } from "../ui/MaxWidth";
import styled from "styled-components";
import CategoryList from "./CategoryList";
import { CategoryListProvider } from "./CategoryListContext";
import { Outlet, useLocation } from "react-router";

export const ContestWrapper = styled.div``;

export const ContestPageTitle = styled.h1`
  font-size: 1.2rem;
  margin: 0 0 1rem 0;
  text-align: center;
`;

export const ContestPageSubTitle = styled.h2`
  font-size: 1rem;
  border-bottom: 1px solid var(--color-border);
  padding-bottom: 0.5rem;
  margin-top: 2rem;
`;

export const ContestMessage = styled.div`
  text-align: center;
  margin-top: 3rem;
`;

export const CategoryMain = () => {
  const location = useLocation();
  const isRoot = location.pathname === `/` || location.pathname === ``;
  return (
    <ContestWrapper>
      <MaxWidthWrapper>
        {isRoot ? (
          <>
            <CategoryList />
            <ContestMessage>
              <h2>Appen för att skapa och hantera pingistävlingar!</h2>
              <p>Denna app är en beta-version.</p>
              <p>
                Starta med att klicka på plustecknet i menyn för att skapa din
                första turnering. Du kommer då bli ombed att registrera dig.
              </p>
              <p>Allting sparas automatiskt hela tiden.</p>
              <p>
                Utvecklare:{" "}
                <a href="mailto:simon@ebelingwebb.se">Simon Ebeling</a>
              </p>
            </ContestMessage>
          </>
        ) : (
          <Outlet />
        )}
      </MaxWidthWrapper>
    </ContestWrapper>
  );
};

const CategoryStartPage = () => (
  <CategoryListProvider>
    <CategoryMain />
  </CategoryListProvider>
);

export default CategoryStartPage;
