export const calculatePlayerStats = (group) => {
  const { players, rounds } = group;

  // Initialize player statistics
  const stats = players.map((player) => ({
    player,
    wins: 0,
    losses: 0,
    matchesPlayed: 0,
    setsWon: 0,
    setsLost: 0,
    pointsWon: 0,
    pointsLost: 0,
    headToHead: {}, // Track head-to-head stats
  }));

  // Calculate player statistics from the schedule
  rounds.forEach((round) => {
    round.forEach((match) => {
      const player1Sets = match.player1.set;
      const player2Sets = match.player2.set;

      const player1Points = match.player1.points?.reduce(
        (sum, p) => sum + p,
        0
      );
      const player2Points = match.player2.points?.reduce(
        (sum, p) => sum + p,
        0
      );

      if (match.winner) {
        const winner = match.winner;
        const loser =
          match.winner.id === match.player1.id ? match.player2 : match.player1;

        // Update player statistics
        const winnerStats = stats.find((stat) => stat.player.id === winner.id);
        const loserStats = stats.find((stat) => stat.player.id === loser.id);

        if (loser.id !== 0) {
          winnerStats.wins++;
          loserStats.losses++;

          winnerStats.matchesPlayed++;
          loserStats.matchesPlayed++;

          const winningSets =
            player1Sets > player2Sets ? player1Sets : player2Sets;
          const losingSets =
            player1Sets < player2Sets ? player1Sets : player2Sets;

          const winningPoints =
            player1Sets > player2Sets ? player1Points : player2Points;
          const losingPoints =
            player1Sets < player2Sets ? player1Points : player2Points;

          winnerStats.setsWon += winningSets;
          winnerStats.setsLost += losingSets;

          loserStats.setsWon += losingSets;
          loserStats.setsLost += winningSets;

          winnerStats.pointsWon += winningPoints;
          winnerStats.pointsLost += losingPoints;

          loserStats.pointsWon += losingPoints;
          loserStats.pointsLost += winningPoints;

          // Update head-to-head stats
          if (!winnerStats.headToHead[loser.id]) {
            winnerStats.headToHead[loser.id] = {
              setsWon: 0,
              setsLost: 0,
              pointsWon: 0,
              pointsLost: 0,
            };
          }
          if (!loserStats.headToHead[winner.id]) {
            loserStats.headToHead[winner.id] = {
              setsWon: 0,
              setsLost: 0,
              pointsWon: 0,
              pointsLost: 0,
            };
          }

          winnerStats.headToHead[loser.id].setsWon += winningSets;
          winnerStats.headToHead[loser.id].setsLost += losingSets;
          winnerStats.headToHead[loser.id].pointsWon += winningPoints;
          winnerStats.headToHead[loser.id].pointsLost += losingPoints;

          loserStats.headToHead[winner.id].setsWon += losingSets;
          loserStats.headToHead[winner.id].setsLost += winningSets;
          loserStats.headToHead[winner.id].pointsWon += losingPoints;
          loserStats.headToHead[winner.id].pointsLost += winningPoints;
        }
      }
    });
  });

  // Calculate set and point difference for each player
  stats.forEach((player) => {
    player.setDifference = player.setsWon - player.setsLost;
    player.pointDifference = player.pointsWon - player.pointsLost;
  });

  // Handle tiebreaks for players with the same match wins
  const resolveTiebreaks = (tiedPlayers) => {
    // Calculate head-to-head stats for tied players
    tiedPlayers.forEach((player) => {
      player.headToHeadStats = {
        setsWon: 0,
        setsLost: 0,
        pointsWon: 0,
        pointsLost: 0,
      };

      tiedPlayers.forEach((opponent) => {
        if (player.player.id !== opponent.player.id) {
          const headToHead = player.headToHead[opponent.player.id];
          if (headToHead) {
            player.headToHeadStats.setsWon += headToHead.setsWon;
            player.headToHeadStats.setsLost += headToHead.setsLost;
            player.headToHeadStats.pointsWon += headToHead.pointsWon;
            player.headToHeadStats.pointsLost += headToHead.pointsLost;
          }
        }
      });

      player.headToHeadStats.setDifference =
        player.headToHeadStats.setsWon - player.headToHeadStats.setsLost;
      player.headToHeadStats.pointDifference =
        player.headToHeadStats.pointsWon - player.headToHeadStats.pointsLost;
    });

    // Sort tied players by head-to-head stats
    return tiedPlayers.sort((a, b) => {
      if (a.headToHeadStats.setDifference !== b.headToHeadStats.setDifference) {
        return (
          b.headToHeadStats.setDifference - a.headToHeadStats.setDifference
        );
      }
      if (
        a.headToHeadStats.pointDifference !== b.headToHeadStats.pointDifference
      ) {
        return (
          b.headToHeadStats.pointDifference - a.headToHeadStats.pointDifference
        );
      }
      return b.pointDifference - a.pointDifference; // Fallback to total point difference
    });
  };

  // Group players by wins and resolve tiebreaks
  const groupedByWins = stats.reduce((acc, player) => {
    if (!acc[player.wins]) acc[player.wins] = [];
    acc[player.wins].push(player);
    return acc;
  }, {});

  const sortedStats = Object.keys(groupedByWins)
    .sort((a, b) => b - a) // Sort groups by wins in descending order
    .flatMap((wins) => {
      const tiedPlayers = groupedByWins[wins];
      return tiedPlayers.length > 1
        ? resolveTiebreaks(tiedPlayers)
        : tiedPlayers;
    });

  return sortedStats;
};
