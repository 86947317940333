import styled from "styled-components";

export const Button = styled.button`
  border: none;
  background: var(--color-primary);
  text-transform: uppercase;
  display: block;
  cursor: pointer;
  letter-spacing: 0.4px;
  border-radius: 4px;
  color: var(--color-white);
  border: 1px solid #333;
  height: 40px;
  padding: 0 1rem;
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
  font-size: 0.9rem;
  &.secondary {
    background: var(--color-secondary);
  }
  &:disabled {
    opacity: 0.8;
    cursor: no-drop;
  }
  &.small {
    height: 32px;
    padding: 0 1rem;
    font-size: 0.8rem;
  }
  &.text {
    background: none;
    color: var(--color-body);
    border: none;
    padding: 0;
    height: auto;
    text-transform: none;
  }
  &.third {
    background: #777;
  }
  > a,
  > a:visited {
    color: var(--color-white);
    text-decoration: none;
  }
`;
