import { minutesToTime, timeToMinutes } from "../../util/helpers";

export const adjustPoolMatchTimes = (
  poolRounds,
  reportIndex,
  matchTimeToAdd
) => {
  const now = new Date();
  const currentMatchEndTime = now.getHours() * 60 + now.getMinutes(); // Nuvarande tid i minuter från midnatt

  let adjustedTime = currentMatchEndTime;

  // Gå igenom rundor och justera tider
  for (
    let roundIndex = reportIndex[0];
    roundIndex < poolRounds.length;
    roundIndex++
  ) {
    const round = poolRounds[roundIndex]; // En enskild runda med matcher

    for (let matchIndex = 0; matchIndex < round.length; matchIndex++) {
      const match = round[matchIndex];

      if (roundIndex === reportIndex[0] && matchIndex === reportIndex[1]) {
        //Rapporterade matchen
        const calculatedTime = adjustedTime - matchTimeToAdd;
        match.time = minutesToTime(calculatedTime);
        match.adjustedTime = calculatedTime - timeToMinutes(match.timeOrg);
      } else if (
        roundIndex > reportIndex[0] ||
        (roundIndex === reportIndex[0] && matchIndex > reportIndex[1])
      ) {
        //Efterföljande matcher
        match.time = minutesToTime(adjustedTime);
        match.adjustedTime = adjustedTime - timeToMinutes(match.timeOrg);
        adjustedTime += matchTimeToAdd;
      }
    }
  }

  return poolRounds; // Returnera de uppdaterade rundorna
};
