import { useState } from "react";
import styled from "styled-components";
import { Button } from "./Button";
import PenIcon from "./FontIcons/PenIcon";

export const Input = styled.input`
  border: 1px solid #ddd;
  background: white;
  font-size: 0.9rem;
  border-radius: 4px;
  height: 40px;
  line-height: 40px;
  padding: 0 5px;
`;

export const InputEditWrapper = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  p {
    margin: 0;
    padding: 0;
  }
  input {
    &[type="text"] {
      flex-grow: 1;
      max-width: 360px;
    }
  }
  button.pen {
    background: none;
    border: none;
    cursor: pointer;
    svg {
      font-size: 1.2rem;
    }
  }
  button.small {
    margin-left: 1rem;
  }
  .buttons-wrapper {
    display: flex;
  }
`;

export const InputEdit = ({
  value = "",
  saveHandler = () => {},
  type = "text",
  disabled = false,
}) => {
  const [isEditing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  return (
    <InputEditWrapper
      onSubmit={(e) => {
        e.preventDefault();
        saveHandler(inputValue);
        setEditing(false);
      }}
    >
      {isEditing ? (
        <Input
          type={type}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      ) : (
        <p>{value}</p>
      )}
      <div className="buttons-wrapper">
        <button
          className="pen"
          type="button"
          onClick={() => setEditing(!isEditing)}
          disabled={disabled}
        >
          {isEditing ? "Stäng" : <PenIcon />}
        </button>
        {isEditing && (
          <Button type="submit" className="small">
            Spara
          </Button>
        )}
      </div>
    </InputEditWrapper>
  );
};
