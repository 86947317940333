import { MatchProvider, useMatchItem } from "./MatchContext";
import styled from "styled-components";
import { useEffect, useState } from "react";
import PlusIcon from "../../ui/FontIcons/PlusIcon";
import MinusIcon from "../../ui/FontIcons/MinusIcon";
import { Button } from "../../ui/Button";
import { useCategory } from "../CategoryContext";
import { useCategoryItem } from "../CategoryItem/CategoryItemContext";
import RotateIcon from "../../ui/FontIcons/RotateIcon";

const LiveMatchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 1rem 1rem;
  margin: 0 auto;
  max-width: 1000px;
  height: calc(100vh - 148px);
  gap: 1rem;
  .player-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 0.5rem;
    width: 100%;
    gap: 2rem;
    .player {
      justify-content: flex-end;
      flex-grow: 1;
      display: flex;
      align-items: center;
      width: 45%;
      h2 {
        margin: 0;
        font-size: 1.2rem;
        &.winner {
          color: var(--color-secondary);
        }
      }
      &:last-child {
        justify-content: flex-start;
      }
    }
    .sets {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      .set-card {
        padding: 0.25rem 0.5rem;
        border-radius: 3px;
        background: var(--color-setRed);
        color: white;
        font-size: 1.2rem;
        font-weight: bold;
      }
    }
  }
  .score-row {
    display: flex;
    justify-content: center;
    width: 100%;
    .player-set-points-wrapper {
      display: flex;
      justify-content: center;
      gap: 4px;
      padding-top: 1rem;
      width: 80px;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      .player-set-points {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.7em;
        font-size: 0.9rem;
        font-weight: bold;
        .seperator {
          margin-left: 4px;
        }
      }
    }
    .score-button {
      display: flex;
      height: 100%;
      width: 40%;
      gap: 1rem;
      &.is-reporter {
        flex-grow: 1;
      }
      .plus-minus-buttons {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        flex-grow: 1;
        button {
          border: none;
          cursor: pointer;
          background: var(--color-pointIncreament);
          color: var(--color-white);
          padding: 0 1rem;
          width: 100%;
          border-radius: 5px;
          font-size: 2rem;
          font-weight: normal;
          flex: 5;
          &.minus {
            flex: 1;
            background: var(--color-setRed);
          }
        }
      }
      .score-card-wrapper {
        background: var(--color-pointCardBlack);
        color: var(--color-white);
        font-size: 10rem;
        font-weight: bold;
        padding: 1rem 0;
        border-radius: 5px;
        width: 2ch;
        text-align: center;
      }
      @media screen and (max-width: 700px) {
        flex-direction: column;
        align-items: center;
        .score-card-wrapper {
          font-size: 5rem;
          width: 100%;
        }
        &.is-reporter {
          .score-card-wrapper {
            width: 100%;
          }
        }
        &:not(.is-reporter) {
          .score-card-wrapper {
            width: 2ch;
          }
        }
        .plus-minus-buttons {
          order: 1;
          width: 100%;
          height: 200px;
        }
      }
    }
  }
  .serve-row {
    display: flex;
    button {
      background: #ccc;
      border-color: #999;
      &.active {
        background: #333;
        border-color: #222;
      }
    }
    .space {
      width: 80px;
    }
  }
  .button-row {
    display: flex;
    justify-content: center;
    width: 100%;
    button {
      padding: 10px 20px;
      font-size: 1rem;
      cursor: pointer;
    }
  }
`;

const PlusMinusButtons = ({ updateScore, player, winner }) => {
  return (
    <div className="plus-minus-buttons">
      <button onClick={() => updateScore(player)} disabled={winner}>
        <PlusIcon />
      </button>
      <button
        className="minus"
        onClick={() => updateScore(player, -1)}
        disabled={winner}
      >
        <MinusIcon />
      </button>
    </div>
  );
};

const PlayerSetPoints = ({ player, useSeperator = false, winner = false }) => {
  if (!player.points || player.points.length === 0) return null;
  const pointsToShow = winner ? player.points : player.points.slice(0, -1);
  return (
    <div className="player-set-points">
      {pointsToShow.map((p, index) => (
        <div className="point" key={player.id + "-" + index + "-" + p}>
          {p}
          {useSeperator && <span className="seperator">-</span>}
        </div>
      ))}
    </div>
  );
};

const LiveMatch = () => {
  const { match } = useMatchItem();
  const { updateResult } = useCategoryItem();
  const { category, isReporter } = useCategory(); // För att få åtkomst till category.set

  const [playerHome, setPlayerHome] = useState(null);
  const [playerAway, setPlayerAway] = useState(null);
  const [winner, setWinner] = useState(null);
  const [currentSet, setCurrentSet] = useState(0);

  const [server, setServer] = useState(null);
  const [startServer, setStartServer] = useState(1);

  const playerHomeScore = playerHome?.points[currentSet] || 0;
  const playerAwayScore = playerAway?.points[currentSet] || 0;
  const totalPoints = playerHomeScore + playerAwayScore;

  useEffect(() => {
    if (totalPoints === 1) {
      // Första poängen: sätt startserver och aktuell server
      const initialServer = 1; // Exempel: börja med spelare 1
      setStartServer(initialServer);
      setServer(initialServer);
    } else if (totalPoints >= 20) {
      // 10-10 och högre: växla server varannan gång
      setServer((prevServer) => (prevServer === 1 ? 2 : 1));
    } else if (totalPoints > 1 && totalPoints % 2 === 0) {
      // Växla server vid jämna totalpoäng
      setServer((prevServer) => (prevServer === 1 ? 2 : 1));
    }
  }, [totalPoints]);

  useEffect(() => {
    if (match) {
      const changed = playerHome && playerHome.id !== match.player1.id;
      const homePlayer = `player${changed ? "2" : "1"}`;
      const awayPlayer = `player${changed ? "1" : "2"}`;

      setPlayerHome({
        ...match[homePlayer],
        set: match[homePlayer].set || 0,
        points: match[homePlayer].points || [0],
      });

      setPlayerAway({
        ...match[awayPlayer],
        set: match[awayPlayer].set || 0,
        points: match[awayPlayer].points || [0],
      });

      if (match.winner) setWinner(match.winner);

      const sets = match.player1.set + match.player2.set;
      if (sets && sets > 0) setCurrentSet(sets);
    }
  }, [match]);

  if (!match || !playerHome || !playerAway) return null;

  const canSaveSet =
    ((playerHomeScore >= 11 || playerAwayScore >= 11) &&
      Math.abs(playerHomeScore - playerAwayScore) === 2) ||
    ((playerHomeScore < 10 || playerAwayScore < 10) &&
      (playerHomeScore === 11 || playerAwayScore === 11));

  const canRotate =
    (playerHomeScore === 0 && playerAwayScore === 0) ||
    ((playerHomeScore === 5 || playerAwayScore === 5) &&
      playerHome.set === category.set - 1 &&
      playerAway.set === category.set - 1);

  const canSaveMatch =
    (playerHome.set === category.set - 1 &&
      playerHome.points[currentSet] >= 11 &&
      playerHome.points[currentSet] - playerAway.points[currentSet] >= 2) ||
    (playerAway.set === category.set - 1 &&
      playerAway.points[currentSet] >= 11 &&
      playerAway.points[currentSet] - playerHome.points[currentSet] >= 2);

  const updateScore = (player, increment = 1) => {
    const isPlayer1 = player === "player1";

    const updatedPlayer1 = { ...playerHome };
    const updatedPlayer2 = { ...playerAway };

    const updatedPlayer = isPlayer1 ? updatedPlayer1 : updatedPlayer2;
    const updatedPoints = [...updatedPlayer.points];
    updatedPoints[currentSet] = Math.max(
      (updatedPoints[currentSet] || 0) + increment,
      0
    );

    if (isPlayer1) {
      updatedPlayer1.points = updatedPoints;
    } else {
      updatedPlayer2.points = updatedPoints;
    }

    setPlayerHome(updatedPlayer1);
    setPlayerAway(updatedPlayer2);

    updateResult(match.matchId, updatedPlayer1, updatedPlayer2);
  };

  const saveSet = () => {
    if (!canSaveSet) return;

    const updatedPlayer1 = { ...playerHome };
    const updatedPlayer2 = { ...playerAway };

    if (playerHomeScore > playerAwayScore) {
      updatedPlayer1.set += 1;
    } else {
      updatedPlayer2.set += 1;
    }

    const playerWithNewSet1 = {
      ...updatedPlayer1,
      points: [...updatedPlayer1.points, 0],
    };
    const playerWithNewSet2 = {
      ...updatedPlayer2,
      points: [...updatedPlayer2.points, 0],
    };

    if (
      updatedPlayer1.set === category.set ||
      updatedPlayer2.set === category.set
    ) {
      updateResult(match.matchId, updatedPlayer1, updatedPlayer2);
      setPlayerHome(playerWithNewSet1);
      setPlayerAway(playerWithNewSet2);
    } else {
      setCurrentSet(currentSet + 1);
      updateResult(match.matchId, playerWithNewSet1, playerWithNewSet2);
    }
  };

  const rotatePlayers = () => {
    setPlayerHome(playerAway);
    setPlayerAway(playerHome);
  };

  return (
    <LiveMatchWrapper>
      <div className="player-row">
        <div className="player">
          <h2 className={playerHome.id === match.winner?.id ? "winner" : ""}>
            {playerHome.name}
          </h2>
        </div>
        <div className="sets">
          <div className="set-card">{playerHome.set}</div>
          <div className="set-card">{playerAway.set}</div>
        </div>
        <div className="player">
          <h2 className={playerAway.id === match.winner?.id ? "winner" : ""}>
            {playerAway.name}
          </h2>
        </div>
      </div>
      <div className="score-row">
        <div className={`score-button ${isReporter ? "is-reporter" : ""}`}>
          {isReporter && (
            <PlusMinusButtons
              updateScore={updateScore}
              player="player1"
              winner={winner}
            />
          )}
          <div className="score-card-wrapper">{playerHomeScore}</div>
        </div>
        <div className="player-set-points-wrapper">
          <PlayerSetPoints
            useSeperator={true}
            player={playerHome}
            winner={match.winner}
            currentSet={currentSet}
          />
          <PlayerSetPoints
            player={playerAway}
            winner={match.winner}
            currentSet={currentSet}
          />
        </div>
        <div className={`score-button ${isReporter ? "is-reporter" : ""}`}>
          <div className="score-card-wrapper">{playerAwayScore}</div>
          {isReporter && (
            <PlusMinusButtons
              updateScore={updateScore}
              player="player2"
              winner={winner}
            />
          )}
        </div>
      </div>
      {!winner && (
        <div className="serve-row">
          <Button
            onClick={() => setServer(1)}
            className={`${server === 1 && "active"} small`}
          >
            {!startServer ? (
              <>
                {server === 2 ? <>Välj som servare</> : <>Vald som servare</>}
              </>
            ) : (
              "Serve"
            )}
          </Button>
          <div className="space" />
          <Button
            onClick={() => setServer(2)}
            className={`${server === 2 && "active"} small`}
          >
            {!startServer ? (
              <>
                {server === 1 ? <>Välj som servare</> : <>Vald som servare</>}
              </>
            ) : (
              "Serve"
            )}
          </Button>
        </div>
      )}
      <div className="button-row">
        {canRotate && !canSaveSet && !winner && isReporter && (
          <Button
            className="secondary"
            onClick={rotatePlayers}
            disabled={!canRotate}
          >
            <RotateIcon />
          </Button>
        )}
        {canSaveSet && isReporter && (
          <Button onClick={saveSet} disabled={!canSaveSet}>
            {canSaveMatch ? "Spara och avsluta matchen" : "Spara set"}
          </Button>
        )}
      </div>
    </LiveMatchWrapper>
  );
};

const MatchMain = () => (
  <MatchProvider>
    <LiveMatch />
  </MatchProvider>
);

export default MatchMain;
