import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { calculatePlayerStats } from "../Common/calcGroupResult";
import { useCategory } from "../CategoryContext";

const ResultTableWrapper = styled.div`
  overflow: auto;
  > h2 {
    text-align: center;
  }
`;

const ResultToplist = styled.table`
  width: 100%;
  min-width: 450px;
  border-collapse: collapse;
  th,
  td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
  }
  th {
    background-color: var(--color-light-grey);
  }
  &.qualifier {
    th {
      background-color: var(--color-secondary-light);
    }
    tbody {
      tr:nth-of-type(2) td {
        border-bottom-color: #777;
        border-bottom-width: 2px;
      }
    }
  }
  &.toplist {
    th {
      background-color: var(--color-white);
    }
    tbody {
      tr:nth-of-type(1) {
        background: #c9b037;
      }
      tr:nth-of-type(2) {
        background: #c5c5c5;
      }
      tr:nth-of-type(3) {
        background: #dbb379;
      }
    }
  }
`;

export const ResultsTable = ({ group, className }) => {
  const [playerStats, setPlayerStats] = useState([]);
  const { category } = useCategory();

  const isRoundRobin = category.type === "round-robin";
  const hasPoints = category.matchReport === "points";
  const hasSets = category.matchReport === "set" || hasPoints;

  useEffect(() => {
    const stats = group && calculatePlayerStats(group);
    setPlayerStats(stats);
  }, [group]);

  if (!group) return null;

  return (
    <ResultTableWrapper>
      <ResultToplist
        className={`${className} ${isRoundRobin ? "" : "qualifier"}`}
      >
        <thead>
          <tr>
            <th>Spelare</th>
            <th>Matcher</th>
            <th>Kvot</th>
            {hasSets && <th>Set</th>}
            {hasPoints && <th>Poäng</th>}
          </tr>
        </thead>
        <tbody>
          {playerStats.map(
            ({
              player,
              wins,
              losses,
              matchesPlayed,
              setsWon,
              setsLost,
              pointsWon,
              pointsLost,
            }) => (
              <tr key={player.id}>
                <td>
                  {player.name}{" "}
                  {player.seed && <span className="seed">({player.seed})</span>}
                </td>
                <td>{matchesPlayed}</td>
                <td>
                  {wins}-{losses}
                </td>
                {hasSets && (
                  <td>
                    {setsWon}-{setsLost}
                  </td>
                )}
                {hasPoints && (
                  <td>
                    {pointsWon}-{pointsLost}
                  </td>
                )}
              </tr>
            )
          )}
        </tbody>
      </ResultToplist>
    </ResultTableWrapper>
  );
};

export default ResultsTable;
