import { shuffleArray } from "./generateKnockoutTree";

export const getNumberOfTables = (type) => {
  if (type === "round-robin") {
    return 2;
  }
  return 1;
};

export const groupPlayersWithSeedings = (players, maxPlayersPerGroup = 4) => {
  const sortedPlayers = [...players].sort((a, b) => b.ranking - a.ranking);
  const numPlayers = players.length;
  const numGroups = Math.ceil(players.length / maxPlayersPerGroup);
  const numIncompletedGroups =
    numPlayers % maxPlayersPerGroup === 0
      ? 0
      : maxPlayersPerGroup - (numPlayers % maxPlayersPerGroup);

  const groups = Array.from({ length: numGroups }, () => []);

  //SEEDED PLAYERS
  const seedPlayers = sortedPlayers.splice(0, numGroups);
  for (let i = 0; i < seedPlayers.length; i++) {
    seedPlayers[i].seed = i + 1;
  }

  //NON SEEDED PLAYERS
  const restPlayers = shuffleArray(sortedPlayers);
  for (let i = 0; i < restPlayers.length; i++) {
    restPlayers[i].seed = false;
  }

  //PLACE PLAYERS
  for (let i = 0; i < groups.length; i++) {
    //SEEDED PLAYER
    groups[i].push(...seedPlayers.splice(0, 1));

    //REST PLAYERS
    const numRestPlayers = maxPlayersPerGroup - 1;
    groups[i].push(
      ...restPlayers.splice(
        0,
        i < numIncompletedGroups ? numRestPlayers - 1 : numRestPlayers
      )
    );
  }

  return groups;
};

export const groupPlayersByRanking = (players, maxPlayersPerGroup = 8) => {
  const sortedPlayers = [...players].sort((a, b) => b.ranking - a.ranking);

  // RESET SEEDINGS
  for (let i = 0; i < sortedPlayers.length; i++) {
    sortedPlayers[i].seed = false;
  }

  // Antal grupper
  const numberOfGroups = Math.ceil(sortedPlayers.length / maxPlayersPerGroup);

  // Beräkna basstorlek för grupper och överskott
  const baseGroupSize = Math.floor(sortedPlayers.length / numberOfGroups);
  const extraPlayers = sortedPlayers.length % numberOfGroups;

  // Fördela spelare till grupper
  const groups = Array.from({ length: numberOfGroups }, (_, i) => {
    const groupSize =
      baseGroupSize + (i >= numberOfGroups - extraPlayers ? 1 : 0); // Extra spelare i de sista grupperna
    return sortedPlayers.splice(0, groupSize);
  });

  return groups;
};

const generateGroupSchedule = (players, category) => {
  const rounds = [];
  let matchId = 1;
  let hasByePlayer = false;

  // Omvandla starttid till minuter
  const [startHour, startMinute] = category.time.split(":").map(Number);
  let matchStartTime = startHour * 60 + startMinute;

  if (players.length % 2 !== 0) {
    players.push({ id: "bye", name: "Bye", ranking: 0 });
    hasByePlayer = true;
  }

  const numPlayers = players.length;
  const numberOfTables = getNumberOfTables(category.type);
  const timeToAdd = parseInt(category.matchTime, 10) / numberOfTables;
  const matchTimeToAdd = parseInt(timeToAdd, 10);

  players.sort((a, b) => b.ranking - a.ranking);

  for (let round = 0; round < numPlayers - 1; round++) {
    const matches = [];
    for (let match = 0; match < numPlayers / 2; match++) {
      const player1 = players[match];
      const player2 = players[numPlayers - 1 - match];

      if (player1.id !== "bye" && player2.id !== "bye") {
        // Beräkna preliminär tid för matchen
        const hours = Math.floor(matchStartTime / 60);
        const minutes = matchStartTime % 60;
        const matchTime = `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}`;

        matches.push({
          matchId: matchId++,
          player1,
          player2,
          winner: false,
          timeOrg: matchTime,
        });

        // Uppdatera tiden för det bordet
        if (!(numberOfTables === 2 && round === 0 && match === 1))
          matchStartTime += matchTimeToAdd;
      }
    }

    rounds.push(matches);

    // Rotate players, keeping the first one fixed
    players.splice(1, 0, players.pop());
  }

  // Remove the bye player if it was added
  if (hasByePlayer) {
    players.pop();
  }

  return rounds;
};

export default generateGroupSchedule;
