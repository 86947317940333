import React, { useState } from "react";
import { auth } from "../../firebase-config";
import { sendPasswordResetEmail } from "firebase/auth";
import { Input } from "../ui/Input";
import { Button } from "../ui/Button";
import AlertBox from "../ui/AlertBox";
import { MaxWidthWrapper } from "../ui/MaxWidth";
import styled from "styled-components";

export const ForgotPasswordWrapper = styled.div`
  &,
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    input {
      margin: 0 0 1rem 0;
    }
    button {
      width: 100%;
    }
  }
`;

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("En återställningslänk har skickats till din e-postadress.");
      setError("");
    } catch (err) {
      setError("Ett fel uppstod. Kontrollera att e-postadressen är korrekt.");
      setMessage("");
    }
  };

  return (
    <MaxWidthWrapper>
      <ForgotPasswordWrapper>
        <h2>Glömt lösenord</h2>
        <form onSubmit={handlePasswordReset}>
          <Input
            type="email"
            placeholder="Ange din e-postadress"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <Button type="submit">Skicka återställningslänk</Button>
        </form>
        {message && <AlertBox variant="success">{message}</AlertBox>}
        {error && <AlertBox variant="danger">{error}</AlertBox>}
      </ForgotPasswordWrapper>
    </MaxWidthWrapper>
  );
};

export default ForgotPassword;
