import React, { useEffect } from "react";
import { Button } from "../../ui/Button";
import { GroupTitle } from "../../ui/GroupTitle";
import { ContestPageTitle } from "../CategoryStartPage";
import { MaxWidthWrapper } from "../../ui/MaxWidth";
import { useCategory } from "../CategoryContext";
import AlertBox from "../../ui/AlertBox";
import LockMessage, { PlayerLockMessage } from "../../ui/LockMessage";
import { getGroupDelay, getCategoryItemStatus } from "../Common/helpers";
import { MatchDelay } from "../Match/Match";
import { useNavigate } from "react-router";

const Groups = () => {
  const {
    category,
    hasPlayers,
    hasSchedule,
    knockoutStageOngoing,
    groupStageCompleted,
    playersIsLocked,
    groupsIsLocked,
    lockGroups,
    isAdmin,
    isReporter,
    generateGroups,
    groups,
  } = useCategory();
  const navigate = useNavigate();

  useEffect(() => {
    if (category.type === "knockout") {
      navigate(`/category/${category.id}/slutspel`);
    }
  }, [category.type, navigate, category.id]);

  if (!playersIsLocked && isAdmin)
    return <PlayerLockMessage name="spelschema" />;

  if (hasPlayers && !hasSchedule && isAdmin) {
    return (
      <MaxWidthWrapper>
        <Button className="generate-schedule" onClick={() => generateGroups()}>
          Skapa spelschema
        </Button>
      </MaxWidthWrapper>
    );
  }

  return (
    <MaxWidthWrapper>
      <>
        <ContestPageTitle>Spelschema</ContestPageTitle>
        {!isAdmin && isReporter && groupsIsLocked && (
          <AlertBox variant="warning">
            <p>Spelschemat är nu färdigspelat och låst av administratören.</p>
          </AlertBox>
        )}
        {isAdmin && (
          <>
            {groupStageCompleted ? (
              <LockMessage
                stageIsLocked={groupsIsLocked}
                lockStage={lockGroups}
                laterStageOngoing={knockoutStageOngoing}
                name="spelschemat"
                nextName="slutspelet"
              />
            ) : (
              <AlertBox variant="info">
                <p>
                  Nu kan du rapportera resultat. När alla pooler är
                  färdigspelade kommer du kunna låsa spelschemat för att gå
                  vidare till nästa steg.
                </p>
              </AlertBox>
            )}
          </>
        )}
      </>
      {hasSchedule ? (
        groups.map((group) => (
          <GroupTitle key={group.id} id={group.id} title={group.name}>
            <span className="status">
              <MatchDelay adjustedTime={getGroupDelay(group)} />
              {getCategoryItemStatus(group)}
            </span>
          </GroupTitle>
        ))
      ) : (
        <AlertBox variant="info">
          <p>Poolerna och spelschemat är inte lottade ännu</p>
        </AlertBox>
      )}
    </MaxWidthWrapper>
  );
};

export default Groups;
