import React, { useRef, useState } from "react";
import Papa from "papaparse";
import styled from "styled-components";
import { Button } from "../../ui/Button";
import { generateId } from "../../util/helpers";

const UploadWrapper = styled.div`
  width: 100%;
  .preview {
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    padding: 1.5rem;
    border-radius: 8px;
    width: 100%;
    margin-top: 1rem;
    overflow-x: auto;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 0.5rem;
    text-align: left;
    &.right {
      text-align: right;
    }
  }

  th {
    background-color: #f1f1f1;
    font-weight: bold;
  }

  .buttons {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    margin-top: 2rem;
  }

  .error {
    color: red;
    font-size: 0.9rem;
    margin-top: 0.5rem;
  }
`;

const ImportButtons = ({ handleImport, handleCancel }) => {
  return (
    <div className="buttons">
      <Button className="import" onClick={handleImport}>
        Importera
      </Button>
      <Button className="third" onClick={handleCancel}>
        Avbryt
      </Button>
    </div>
  );
};

const UploadPlayerList = ({ onPlayersUpload, locked }) => {
  const fileInputRef = useRef(null);
  const [players, setPlayers] = useState([]);
  const [fileName, setFileName] = useState("");
  const [error, setError] = useState("");

  const handleFileSelection = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      // Kontrollera filtyp
      if (file.type !== "text/csv") {
        setError("Endast CSV-filer är tillåtna.");
        return;
      }

      setError("");
      setFileName(file.name);
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          const parsedPlayers = result.data.map((player) => ({
            id: generateId(),
            name: player.Namn || "Okänd",
            ranking: parseInt(player["Rankingpoäng"], 10) || 0,
          }));
          setPlayers(parsedPlayers);
        },
        error: (error) => {
          console.error("Fel vid CSV-analys:", error);
          setError("Kunde inte läsa CSV-filen. Kontrollera formatet.");
        },
      });
    }
  };

  const handleImport = () => {
    try {
      onPlayersUpload(players);
      setPlayers([]);
      setFileName("");
    } catch (error) {
      console.error("Fel vid importering:", error);
      setError("Ett fel inträffade vid importeringen. Försök igen.");
    }
  };

  const handleCancel = () => {
    setPlayers([]);
    setFileName("");
    setError("");
    fileInputRef.current.value = "";
  };

  const hasPlayers = players.length > 0;

  return (
    <UploadWrapper>
      {!hasPlayers && (
        <Button type="button" disabled={locked} onClick={handleFileSelection}>
          Välj fil
        </Button>
      )}

      <input
        type="file"
        accept=".csv"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileUpload}
        disabled={locked}
      />
      {error && <div className="error">{error}</div>}
      {hasPlayers && (
        <div className="preview">
          <h4>{fileName}</h4>
          <p>{players.length} spelare redo att importeras.</p>
          <table>
            <thead>
              <tr>
                <th>Namn</th>
                <th className="right">Rankingpoäng</th>
              </tr>
            </thead>
            <tbody>
              {players.map((player, index) => (
                <tr key={index}>
                  <td>{player.name}</td>
                  <td className="right">{player.ranking}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <ImportButtons {...{ handleImport, handleCancel }} />
        </div>
      )}
    </UploadWrapper>
  );
};

export default UploadPlayerList;
