import React, { useState } from "react";
import { Button } from "../../ui/Button";
import styled from "styled-components";
import { theme } from "../../../theme";
import { generateId } from "../../util/helpers";

export const FormWrapper = styled.form`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  input {
    flex-grow: 1;
    border: 1px solid #ccc;
    height: 40px;
    border-radius: 5px;
    padding: 0 5px;
    margin-right: 10px;
  }
  button {
  }
  select {
    height: 40px;
    border-radius: 5px;
    padding: 0 5px;
    border: 1px solid #999;
  }
  &.player-form {
    ${theme.media.sm} {
      input:first-child {
        width: 100%;
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }
  }
`;

const PlayerForm = ({ addPlayer, locked }) => {
  const [name, setName] = useState("");
  const [ranking, setRanking] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name && ranking) {
      addPlayer({
        name,
        ranking: parseInt(ranking),
        id: generateId(),
        seed: false,
      });
      setName("");
      setRanking("");
    }
  };

  return (
    <FormWrapper onSubmit={handleSubmit} className="player-form">
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Spelare"
      />
      <input
        type="number"
        value={ranking}
        onChange={(e) => setRanking(e.target.value)}
        placeholder="Ranking"
      />
      <Button type="submit" disabled={locked}>
        Lägg till
      </Button>
    </FormWrapper>
  );
};

export default PlayerForm;
