import React from "react";
import { Button } from "../ui/Button";
import styled from "styled-components";
import PlayerForm from "./Players/PlayerForm";
import PlayerListView from "./Players/PlayerListView";
import { ContestPageSubTitle, ContestPageTitle } from "./CategoryStartPage";
import { MaxWidthWrapper } from "../ui/MaxWidth";
import { useCategory } from "./CategoryContext";
import UploadPlayerList from "./Players/UploadPlayers";
import LockMessage from "../ui/LockMessage";
import AlertBox from "../ui/AlertBox";

const PlayersWrapper = styled.div``;

export const PlayerMessage = () => {
  const { minPlayers, numberOfPlayers } = useCategory();
  return (
    <AlertBox className="warning">
      <p>
        Lägg till {minPlayers - numberOfPlayers} spelare för att kunna låsa
        listan och sedan gå vidare att generera ett spelschema
      </p>
    </AlertBox>
  );
};

const Players = () => {
  const {
    addPlayer,
    addPlayers,
    clearPlayers,
    hasPlayers,
    numberOfPlayers,
    lockPlayers,
    playersIsLocked,
    groupStageOngoing,
    knockoutStageOngoing,
    isAdmin,
  } = useCategory();

  const handleUpload = (players) => {
    addPlayers(players);
  };

  const ongoing = groupStageOngoing || knockoutStageOngoing;

  return (
    <MaxWidthWrapper>
      <PlayersWrapper>
        <ContestPageTitle>Spelare ({numberOfPlayers})</ContestPageTitle>
        {hasPlayers && isAdmin && (
          <LockMessage
            stageIsLocked={playersIsLocked}
            lockStage={lockPlayers}
            laterStageOngoing={ongoing}
            name="spelarlistan"
            nextName="spelschema eller slutspel"
          />
        )}
        {!playersIsLocked && isAdmin && (
          <>
            <ContestPageSubTitle>Lägg till Spelare</ContestPageSubTitle>
            <PlayerForm addPlayer={addPlayer} locked={playersIsLocked} />
            <ContestPageSubTitle>Eller importera via CSV</ContestPageSubTitle>
            <UploadPlayerList
              locked={playersIsLocked}
              onPlayersUpload={handleUpload}
            />
          </>
        )}
        <ContestPageSubTitle>Spelarlista</ContestPageSubTitle>
        <PlayerListView locked={playersIsLocked}>
          {numberOfPlayers > 0 && isAdmin && (
            <Button
              disabled={playersIsLocked}
              className="text"
              onClick={clearPlayers}
            >
              Rensa spelare
            </Button>
          )}
        </PlayerListView>
        {!hasPlayers && <PlayerMessage />}
      </PlayersWrapper>
    </MaxWidthWrapper>
  );
};

export default Players;
