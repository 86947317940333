import styled from "styled-components";
import { theme } from "../theme";

const NavWrapper = styled.nav`
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: center;
  background: var(--color-primary-medium3-light);
  a,
  a:visited {
    margin: 0 5px;
    color: var(--color-white);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    &.active {
      text-decoration: underline;
    }
  }
  &.sticky {
    position: sticky;
    top: 0;
    z-index: 999;
  }
  @media (orientation: landscape) {
    ${theme.media.mdDown} {
      height: 32px;
      > a {
        font-size: 0.9rem;
      }
    }
  }
`;

const SubMenu = ({ children, className }) => {
  return <NavWrapper className={`sub-nav ${className}`}>{children}</NavWrapper>;
};

export default SubMenu;
