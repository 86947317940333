import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import {
  byePlayer,
  generateKnockoutTree,
  generateKnockoutTreeFromGroups,
} from "./Common/generateKnockoutTree";
import { addAndSort, addManyAndSort, sortPlayers } from "./Players/helpers";
import { useParams } from "react-router";
import {
  updateCategory,
  deleteCategory,
  listenToCategory,
} from "../Database/categoryService";
import {
  hasGroupsInCategory,
  hasKnockoutInCategory,
  isGroupKnockout,
  isStageCompleted,
  isStageOngoing,
} from "./Common/helpers";
import { useAuth } from "../Auth/AuthContext";
import generateGroupSchedule, {
  groupPlayersByRanking,
  groupPlayersWithSeedings,
} from "./Common/generateGroupSchedule";
import {
  createCategoryItemsInBulk,
  deleteAllCategoryItems,
  initialCategoryItem,
  listenToCategoryItems,
} from "../Database/categoryItemService";
import {
  convertRoundsForFirestore,
  restoreRoundsForFirestore,
} from "../Database/helpers";
import { indexToChar } from "../util/helpers";
import { calculatePlayerStats } from "./Common/calcGroupResult";

const CategoryContext = createContext();

const initialStages = {
  groups: [],
  rounds: [],
  lock: {
    players: false,
    groups: false,
    knockout: false,
  },
};

export const CategoryProvider = ({ children }) => {
  const [category, setCategory] = useState(false);
  const [groups, setGroups] = useState([]);
  const [knockouts, setKnockouts] = useState([]);
  const [error, setError] = useState("");
  const { categoryId } = useParams();
  const { user } = useAuth();

  //Update category
  const saveCategory = async (updatedCategory) => {
    if (updatedCategory && updatedCategory.id) {
      await updateCategory(updatedCategory);
      setCategory(updatedCategory);
    }
  };

  /** Ta bort turnering */
  const removeCategory = async (categoryId) => {
    if (!categoryId) return;
    await deleteCategory(categoryId); // Ta bort från Firebase
    setCategory(false); // Återställ lokalt
  };

  // Lägg till spelare
  const addPlayer = (player) => {
    const newCategory = {
      ...category,
      players: addAndSort(category.players || [], player),
      ...initialStages,
    };
    clearGroups();
    clearKnockouts();
    saveCategory(newCategory);
  };

  // Lägg till flera spelare
  const addPlayers = (players) => {
    const newCategory = {
      ...category,
      players: addManyAndSort(category.players || [], players),
      ...initialStages,
    };
    clearGroups();
    clearKnockouts();
    saveCategory(newCategory);
  };

  // Ta bort spelare
  const removePlayer = (playerId) => {
    const updatedPlayers = category.players.filter(
      (player) => player.id !== playerId
    );
    const newCategory = {
      ...category,
      players: updatedPlayers,
      ...initialStages,
    };
    clearGroups();
    clearKnockouts();
    saveCategory(newCategory);
  };

  // Ta bort alla spelare
  const clearPlayers = () => {
    const newCategory = {
      ...category,
      players: [],
      ...initialStages,
    };
    clearGroups();
    clearKnockouts();
    saveCategory(newCategory);
  };

  // Uppdatera en enskild spelare
  const updatePlayer = (playerId, newName, newRanking) => {
    const updatedPlayers = category.players.map((player) => {
      if (player.id === playerId) {
        return { ...player, name: newName, ranking: newRanking };
      }
      return player;
    });
    const newCategory = {
      ...category,
      players: sortPlayers(updatedPlayers),
      ...initialStages,
    };
    clearGroups();
    clearKnockouts();
    saveCategory(newCategory);
  };

  // Uppdatera flera spelare
  const updatePlayers = (updatedPlayers) => {
    updatedPlayers.sort((a, b) => b.ranking - a.ranking);
    const newCategory = {
      ...category,
      players: updatedPlayers,
      ...initialStages,
    };
    clearGroups();
    saveCategory(newCategory);
  };

  const lockPlayers = (value = true) => {
    saveCategory({
      ...category,
      lock: {
        ...category.lock,
        players: value,
      },
    });
  };

  const lockGroups = (value = true) => {
    saveCategory({
      ...category,
      lock: {
        ...category.lock,
        groups: value,
      },
    });
  };

  const lockKnockout = (value = true) => {
    saveCategory({
      ...category,
      lock: {
        ...category.lock,
        knockouts: value,
      },
    });
  };

  // LISTEN TO CATEGORY ITEMS
  const useCategoryItemsListener = (
    categoryId,
    itemType,
    sortField,
    setItems
  ) => {
    useEffect(() => {
      if (!categoryId) return;

      const unsubscribe = listenToCategoryItems(
        categoryId,
        itemType,
        sortField,
        (fetchedItems) => {
          setItems(
            fetchedItems.map((item) => ({
              ...item,
              rounds: restoreRoundsForFirestore(item.rounds),
            }))
          );
        }
      );

      return () => unsubscribe();
    }, [categoryId, itemType, sortField, setItems]);
  };

  // CREATE CATEGORY ITEMS
  const createCategoryItems = async (items, itemType) => {
    if (!category || !category.id) {
      console.error("Category not found or ID is missing");
      return;
    }
    try {
      await createCategoryItemsInBulk(category.id, items, itemType);
    } catch (error) {
      console.error(`Error creating ${itemType}:`, error.message);
      throw error;
    }
  };

  // CLEAR CATEGORY ITEMS
  const clearCategoryItems = async (itemType, updateCategory = false) => {
    if (!category || !category.id) {
      console.error("Category not found or ID is missing");
      return;
    }

    try {
      if (!category[itemType] || category[itemType].length === 0) {
        return;
      }

      await deleteAllCategoryItems(category.id, itemType);

      if (updateCategory) {
        const updatedCategory = {
          ...category,
          lock: {
            ...category.lock,
            [itemType]: false,
          },
          [itemType]: [],
        };
        saveCategory(updatedCategory);
      }
    } catch (error) {
      console.error(`Error clearing ${itemType}:`, error.message);
      throw error;
    }
  };

  //Groups list
  useCategoryItemsListener(category.id, "groups", "groupNumber", setGroups);
  const createGroups = (groups) => {
    createCategoryItems(groups, "groups");
  };
  const clearGroups = (updatedCategory = false) => {
    clearCategoryItems("groups", updatedCategory);
  };

  const generateGroups = () => {
    const isSmallGroup = category.type !== "round-robin";
    const groupOfPlayers = isSmallGroup
      ? groupPlayersWithSeedings(category.players, category.groupAmount)
      : groupPlayersByRanking(category.players, category.groupAmount);
    const groups = groupOfPlayers.map((players, index) => {
      return {
        ...initialCategoryItem,
        name: `${isSmallGroup ? "Pool" : "Division"} ${index + 1}`,
        groupNumber: index + 1,
        players,
        rounds: convertRoundsForFirestore(
          generateGroupSchedule(players, category)
        ),
      };
    });
    createGroups(groups);
  };

  //Knockout list
  useCategoryItemsListener(
    category.id,
    "knockouts",
    "knockoutType",
    setKnockouts
  );
  const createKnockouts = (knockouts) => {
    createCategoryItems(knockouts, "knockouts");
  };
  const clearKnockouts = (updatedCategory = false) => {
    clearCategoryItems("knockouts", updatedCategory);
  };

  const getKnockout = (index = 0) => {
    return {
      ...initialCategoryItem,
      name: indexToChar(index) + "-slutspel",
      knockoutType: indexToChar(index),
    };
  };

  const addKnockoutPlayer = (list, stats, index) => {
    if (typeof stats[index] !== "undefined") {
      list.push(stats[index].player);
    } else {
      list.push(byePlayer);
    }
  };

  const generateKnockouts = () => {
    const isGroupKnockoutType = isGroupKnockout(category.type);
    const isMultiKnockouts = category.type === "group-knockout-multi";

    const knockouts = [];
    if (isGroupKnockoutType) {
      const playersInGroups = groups.reduce((max, group) => {
        const playerCount = group.players ? group.players.length : 0;
        return Math.max(max, playerCount);
      }, 0);

      const knockoutPools = Array.from({ length: playersInGroups }, () => []); // Dynamiskt skapa pools för alla positioner

      for (const group of groups) {
        const groupStats = calculatePlayerStats(group);

        // Dynamiskt fördela spelare till rätt pooler
        // Se till att loopen alltid kör för alla 'playersInGroups'
        for (let index = 0; index < playersInGroups; index++) {
          addKnockoutPlayer(knockoutPools[index], groupStats, index);
        }
      }

      // Generera knockouts för varje par av pools
      for (let i = 0; i < knockoutPools.length; i += 2) {
        if (isMultiKnockouts || i === 0) {
          knockouts.push({
            ...getKnockout(i / 2),
            ...generateKnockoutTreeFromGroups(
              knockoutPools[i],
              knockoutPools[i + 1] || []
            ),
          });
        }
      }
    } else {
      const knockout = {
        ...getKnockout(),
        players: category.players,
        rounds: generateKnockoutTree(players),
      };
      knockouts.push(knockout);
    }

    createKnockouts(
      knockouts.map((x) => ({
        ...x,
        rounds: convertRoundsForFirestore(x.rounds),
      }))
    );
  };

  //Lyssna på kategori
  useEffect(() => {
    if (!categoryId) return;

    // Lyssna på Firebase i realtid
    const unsubscribe = listenToCategory(categoryId, (fetchedCategory) => {
      if (fetchedCategory) {
        setCategory(fetchedCategory); // Uppdatera state
      } else {
        console.warn(`Kategori med id ${categoryId} hittades inte.`);
        setCategory(false);
      }
    });

    // Avsluta lyssnaren vid unmount
    return () => unsubscribe();
  }, [categoryId]);

  const isAdmin = useMemo(() => {
    if (!category || !user) return false;
    return category.owner.id === user.uid;
  }, [category, user]);

  const isReporter = useMemo(() => {
    if (!category || !user) return false;
    return (
      category.owner.id === user.uid || category.reporters?.includes(user.email)
    );
  }, [category, user]);

  const hasKnockout = hasKnockoutInCategory(category.type);
  const hasGroups = hasGroupsInCategory(category.type);

  const hasSchedule = category.groups?.length > 0;

  const hasPlayers = category && category.players?.length >= 4; // minPlayers
  const players = category?.players;
  const numberOfPlayers = players ? players.length : 0;

  const groupStageOngoing = useMemo(() => isStageOngoing(groups), [groups]);

  const groupStageCompleted = useMemo(() => isStageCompleted(groups), [groups]);

  const knockoutStageOngoing = useMemo(
    () => isStageOngoing(knockouts),
    [knockouts]
  );

  const knockoutStageCompleted = useMemo(
    () => isStageCompleted(knockouts),
    [knockouts]
  );

  const resultReady =
    (category.type === "round-robin" && groupStageCompleted) ||
    knockoutStageCompleted;

  const playersIsLocked = category?.lock?.players;
  const groupsIsLocked = category?.lock?.groups;
  const knockoutIsLocked = category?.lock?.knockouts;

  const contextValue = {
    category,
    players,
    numberOfPlayers,

    error,
    setError,

    groups,
    clearGroups,
    generateGroups,

    knockouts,
    clearKnockouts,
    generateKnockouts,

    isAdmin,
    isReporter,

    saveCategory,
    removeCategory,

    hasKnockout,
    hasGroups,
    hasSchedule,
    resultReady,

    addPlayer,
    addPlayers,
    clearPlayers,
    removePlayer,
    updatePlayer,
    hasPlayers,
    updatePlayers,
    minPlayers: 4,

    lockPlayers,
    playersIsLocked,
    lockGroups,
    groupsIsLocked,
    lockKnockout,
    knockoutIsLocked,

    groupStageOngoing,
    groupStageCompleted,
    knockoutStageOngoing,
    knockoutStageCompleted,
  };

  return (
    <CategoryContext.Provider value={contextValue}>
      {children}
    </CategoryContext.Provider>
  );
};

export const useCategory = () => useContext(CategoryContext);
