import {
  collection,
  doc,
  writeBatch,
  onSnapshot,
  updateDoc,
  getDocs,
  query,
  orderBy,
} from "firebase/firestore";
import { firestore } from "../../firebase-config";

const validateGroupData = (item) => {
  if (!item.name || typeof item.name !== "string") {
    throw new Error(
      "Invalid item data: 'name' is required and must be a string."
    );
  }
};

export const createCategoryItemsInBulk = async (
  categoryId,
  items,
  itemType
) => {
  const itemsRef = collection(
    firestore,
    `categories/${categoryId}/${itemType}`
  );

  // Lägg till nya objekt
  const batchCreate = writeBatch(firestore);
  const itemIds = [];

  items.forEach((item) => {
    validateGroupData(item);
    const itemDoc = doc(itemsRef);
    itemIds.push(itemDoc.id);
    batchCreate.set(itemDoc, item);
  });

  await batchCreate.commit();
  const categoryRef = doc(firestore, `categories/${categoryId}`);
  await updateDoc(categoryRef, { [itemType]: itemIds });
};

export const deleteAllCategoryItems = async (categoryId, itemType) => {
  if (!categoryId) {
    throw new Error("Category ID is required to delete all items.");
  }

  const batch = writeBatch(firestore);
  const itemsRef = collection(
    firestore,
    `categories/${categoryId}/${itemType}`
  );

  try {
    // Hämta alla grupper i kategorin
    const snapshot = await getDocs(itemsRef);
    if (snapshot.empty) {
      console.warn("No items found for this category.");
      return;
    }

    // Lägg till varje grupp i batchen för borttagning
    snapshot.forEach((doc) => {
      batch.delete(doc.ref);
    });

    // Bekräfta batchoperationen
    await batch.commit();
    console.log("All items have been successfully deleted.");
  } catch (error) {
    console.error("Error deleting items in bulk:", error);
    throw error;
  }
};

export const updateCategoryItem = async (categoryId, item, itemType) => {
  if (!categoryId || !item.id) {
    throw new Error("Category ID and Group ID are required to update a group.");
  }
  const groupDoc = doc(
    firestore,
    `categories/${categoryId}/${itemType}/${item.id}`
  );
  await updateDoc(groupDoc, item);
};

export const listenToCategoryItem = (
  categoryId,
  itemId,
  itemType,
  callback
) => {
  if (!categoryId || !itemId) {
    throw new Error(
      "Category ID and Group ID are required to listen to a group."
    );
  }
  const itemDoc = doc(
    firestore,
    `categories/${categoryId}/${itemType}/${itemId}`
  );
  return onSnapshot(itemDoc, (docSnapshot) => {
    callback(
      docSnapshot.exists()
        ? { id: docSnapshot.id, ...docSnapshot.data() }
        : null
    );
  });
};

export const listenToCategoryItems = (
  categoryId,
  itemType,
  orderByKey,
  callback = () => {}
) => {
  if (!categoryId) {
    throw new Error("Category ID is required to listen to items.");
  }
  const itemsRef = collection(
    firestore,
    `categories/${categoryId}/${itemType}`
  );
  const itemsQuery = query(itemsRef, orderBy(orderByKey));
  return onSnapshot(itemsQuery, (snapshot) => {
    const items = snapshot.empty
      ? []
      : snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
    callback(items);
  });
};

export const initialCategoryItem = {
  name: "",
  players: [],
  rounds: [],
};
