import React, { useState } from "react";
import { useCategoryList } from "./CategoryListContext"; // Använd context istället för direkt Firebase-hantering
import { Input } from "../ui/Input";
import { Button } from "../ui/Button";
import AlertBox from "../ui/AlertBox";
import { MaxWidthWrapper } from "../ui/MaxWidth";
import styled from "styled-components";
import useAuthRedirect from "../Auth/hooks/useAuthRedirect";
import { getCurrentDate, getCurrentTime } from "../util/helpers";

export const Wrapper = styled.div`
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    > input {
      width: 100%;
    }
  }
  .radio-wrapper {
    label {
      margin: 5px;
    }
  }
`;

const CreateCategory = () => {
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [visibility, setVisibility] = useState("public");
  const [date, setDate] = useState(getCurrentDate());
  const [time, setTime] = useState(getCurrentTime()); // Nytt state för tid
  const { addCategory, error, removeError } = useCategoryList();

  useAuthRedirect("/login");

  const handleCreateCategory = async (e) => {
    e.preventDefault();
    await addCategory({
      name,
      location,
      visibility,
      date,
      time,
    });
  };

  return (
    <MaxWidthWrapper>
      <Wrapper>
        <h2>Skapa en ny turnering</h2>
        <form onSubmit={handleCreateCategory}>
          <Input
            type="text"
            placeholder="Turneringens namn"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <Input
            type="text"
            placeholder="Plats"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            required
          />
          <Input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />
          <Input
            type="time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
            required
          />
          <div className="radio-wrapper">
            <label>
              <input
                type="radio"
                value="public"
                checked={visibility === "public"}
                onChange={() => setVisibility("public")}
              />
              Publik
            </label>
            <label>
              <input
                type="radio"
                value="private"
                checked={visibility === "private"}
                onChange={() => setVisibility("private")}
              />
              Privat
            </label>
          </div>
          <Button type="submit">Spara</Button>
        </form>
        {error && (
          <AlertBox variant="danger" onClose={() => removeError()}>
            {error}
          </AlertBox>
        )}
      </Wrapper>
    </MaxWidthWrapper>
  );
};

export default CreateCategory;
