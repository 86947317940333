import ButtonMenu from "../../ButtonMenu";
import { Button } from "../../ui/Button";
import TrashIcon from "../../ui/FontIcons/TrashIcon";
import { MaxWidthWrapper } from "../../ui/MaxWidth";
import { ContestPageSubTitle, ContestPageTitle } from "../CategoryStartPage";
import { useCategory } from "../CategoryContext";
import { useNavigate } from "react-router-dom";
import { InputEdit } from "../../ui/Input";
import AlertBox from "../../ui/AlertBox";
import { useState } from "react";
import useAdminRedirect from "../Common/hooks/useAdminRedirect";
import Reporters from "./Reporters";

export const type = [
  { name: "Poolspel + Slutspel", type: "group-knockout" },
  { name: "Poolspel + A/B/C-Slutspel", type: "group-knockout-multi" },
  { name: "Slutspel", type: "knockout" },
  { name: "Storpool", type: "round-robin" },
];

export const groupAmount = [
  { name: "3 st", value: 3 },
  { name: "4 st", value: 4 },
  { name: "5 st", value: 5 },
  { name: "6 st", value: 6 },
  { name: "7 st", value: 7 },
  { name: "8 st", value: 8 },
  { name: "9 st", value: 9 },
  { name: "10 st", value: 10 },
];

export const set = [
  { name: "7 set", value: 4 },
  { name: "5 set", value: 3 },
  { name: "3 set", value: 2 },
  { name: "1 set", value: 1 },
];

export const matchReport = [
  { name: "Vinnare", value: "winner" },
  { name: "Set", value: "set" },
  { name: "Poäng", value: "points" },
];

export const visibilityOptions = [
  { name: "Publik", value: "public" },
  { name: "Privat", value: "private" },
];

const Settings = () => {
  useAdminRedirect();
  const {
    category,
    removeCategory,
    saveCategory,
    groupStageOngoing,
    knockoutStageOngoing,
    error,
    setError,
    clearGroups,
    clearKnockouts,
  } = useCategory();
  const navigate = useNavigate();
  const ongoing = groupStageOngoing || knockoutStageOngoing;
  const [locked, setLock] = useState(ongoing);

  const handleFieldUpdate = (field, value) => {
    const updatedCategory = { ...category, [field]: value };

    if (field === "type") {
    }

    if (
      field === "set" ||
      field === "type" ||
      field === "time" ||
      field === "groupAmount" ||
      field === "matchTime" ||
      field === "matchReport"
    ) {
      clearGroups();
      clearKnockouts();
      updatedCategory.groups = [];
      updatedCategory.knockouts = [];

      if (field === "matchReport" && value === "winner")
        updatedCategory.set = 1;

      if (field === "type") {
        if (value === "group-knockout" || value === "group-knockout-multi") {
          updatedCategory.groupAmount = 4;
        }
        if (value === "round-robin") {
          updatedCategory.groupAmount = 8;
        }
      }
    }
    updatedCategory.lock.groups = updatedCategory.lock.knockouts = false;
    saveCategory(updatedCategory);
  };

  const handleRemoveCategory = () => {
    if (
      category &&
      window.confirm(`Är du säker att du vill ta bort: ${category.name}?`)
    ) {
      removeCategory(category.id);
      navigate(`/`);
    }
  };

  return (
    <MaxWidthWrapper>
      <ContestPageTitle>Inställningar</ContestPageTitle>
      {ongoing && (
        <AlertBox variant="warning">
          <p>
            Obs! Denna turnering är nu igång. Om du gör förändringar i
            inställningarna som påverkar spelschemat så kommer detta att behöva
            skapas på nytt.
          </p>
          {locked && (
            <>
              <p>
                Om du vet vad du gör så kan du låsa upp dessa inställningar på
                knappen nedan.
              </p>
              <Button className="small" onClick={() => setLock(false)}>
                Lås upp inställningar
              </Button>
            </>
          )}
        </AlertBox>
      )}
      <ContestPageSubTitle>Namn</ContestPageSubTitle>
      <InputEdit
        value={category.name}
        saveHandler={(value) => handleFieldUpdate("name", value)}
      />
      <ContestPageSubTitle>Plats</ContestPageSubTitle>
      <InputEdit
        value={category.location}
        saveHandler={(value) => handleFieldUpdate("location", value)}
      />
      <ContestPageSubTitle>Datum</ContestPageSubTitle>
      <InputEdit
        type="date"
        value={category.date}
        saveHandler={(value) => handleFieldUpdate("date", value)}
        disabled={locked}
      />
      <ContestPageSubTitle>Tid</ContestPageSubTitle>
      <InputEdit
        type="time"
        value={category.time}
        saveHandler={(value) => handleFieldUpdate("time", value)}
        disabled={locked}
      />
      <ContestPageSubTitle>Spelform</ContestPageSubTitle>
      <ButtonMenu>
        {type.map((item, index) => (
          <button
            key={index}
            className={category.type === item.type ? "active" : ""}
            onClick={() => handleFieldUpdate("type", item.type)}
            disabled={locked}
          >
            {item.name}
          </button>
        ))}
      </ButtonMenu>

      <ContestPageSubTitle>Antal i varje pool</ContestPageSubTitle>
      <ButtonMenu>
        {groupAmount.map((item, index) => (
          <button
            key={index}
            className={category.groupAmount === item.value ? "active" : ""}
            onClick={() => handleFieldUpdate("groupAmount", item.value)}
            disabled={locked || category.type === "knockout"}
          >
            {item.name}
          </button>
        ))}
      </ButtonMenu>
      <ContestPageSubTitle>Rapportera</ContestPageSubTitle>
      <ButtonMenu>
        {matchReport.map((item, index) => (
          <button
            key={index}
            className={category.matchReport === item.value ? "active" : ""}
            onClick={() => handleFieldUpdate("matchReport", item.value)}
            disabled={locked}
          >
            {item.name}
          </button>
        ))}
      </ButtonMenu>
      <ContestPageSubTitle>Antal set (bäst av)</ContestPageSubTitle>
      <ButtonMenu>
        {set.map((item, index) => (
          <button
            key={index}
            className={category.set === item.value ? "active" : ""}
            onClick={() => handleFieldUpdate("set", item.value)}
            disabled={locked || category.matchReport === "winner"}
          >
            {item.name}
          </button>
        ))}
      </ButtonMenu>
      <ContestPageSubTitle>Beräknad matchlängd (min)</ContestPageSubTitle>
      <InputEdit
        type="number"
        value={category.matchTime}
        saveHandler={(value) => handleFieldUpdate("matchTime", value)}
        disabled={locked}
      />
      <ContestPageSubTitle>Rapportörer</ContestPageSubTitle>
      <Reporters />
      <ContestPageSubTitle>Synlighet</ContestPageSubTitle>
      <ButtonMenu>
        {visibilityOptions.map((option, index) => (
          <button
            key={index}
            className={category.visibility === option.value ? "active" : ""}
            onClick={() => handleFieldUpdate("visibility", option.value)}
          >
            {option.name}
          </button>
        ))}
      </ButtonMenu>
      <ContestPageSubTitle>Radera turneringen</ContestPageSubTitle>
      <Button
        type="button"
        className="secondary remove right"
        onClick={handleRemoveCategory}
      >
        <TrashIcon />
      </Button>
      {error && (
        <AlertBox variant="danger" onClose={() => setError("")}>
          {error}
        </AlertBox>
      )}
    </MaxWidthWrapper>
  );
};

export default Settings;
