import React from "react";
import styled from "styled-components";
import { useCategory } from "../../CategoryContext";
import { useCategoryItem } from "../../CategoryItem/CategoryItemContext";
import { Match } from "../../Match/Match";

const KnockoutTreeWrapper = styled.div`
  overflow: hidden;
  .rounds {
    display: flex;
    overflow-x: auto;
  }
  .round {
    text-align: left;
    margin: 20px 0;
    width: 370px;
    flex: 0 0 auto;
    margin-right: 30px;
    position: relative;
    justify-content: space-around;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    > h4 {
      text-align: center;
      margin: 0;
      text-transform: uppercase;
      position: absolute;
      top: 0px;
    }
    .matches {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .match {
      margin: 10px 0;
      border: 1px solid #ddd;
      border-radius: 10px;
      position: relative;
    }
    &:not(:nth-of-type(1)) {
      .match {
        .bracket {
          content: "";
          width: 18px;
          height: 144px;
          border: 2px solid #d7d9dc;
          border-top-right-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
          border-left: 0;
          position: absolute;

          left: -32px;
        }
        .connector {
          content: "";
          width: 14px;
          height: 2px;
          background: #d7d9dc;
          position: absolute;
          top: 50%;
          left: -14px;
        }
      }
    }
    &[data-round="2"] {
      .match {
        .bracket {
          height: 144px;
          top: -7%;
        }
      }
    }
    &[data-round="3"] {
      .match {
        .bracket {
          height: 282px;
          top: -69%;
        }
      }
    }
    &[data-round="4"] {
      .match {
        .bracket {
          height: 566px;
          top: -188%;
        }
      }
    }
    &[data-round="5"] {
      .match {
        .bracket {
          height: 1132px;
          top: -425%;
        }
      }
    }
    &[data-round="6"] {
      .match {
        .bracket {
          height: 2261px;
          top: -1070px;
        }
      }
    }
  }
`;

const getRoundNames = (matches) => {
  if (matches === 1) return "Final";
  if (matches === 2) return "Semifinal";
  if (matches === 4) return "Kvartsfinal";
  if (matches === 8) return "Åttondelsfinal";
  if (matches > 8) return `1/${matches}-delsfinal`;
};

const KnockoutTree = () => {
  const { knockoutIsLocked } = useCategory();
  const { item: knockout } = useCategoryItem();

  if (!knockout) return null;

  return (
    <KnockoutTreeWrapper>
      <div className="rounds">
        {knockout.rounds?.map((round, roundIndex) => (
          <div key={roundIndex} className="round" data-round={roundIndex + 1}>
            <h4>{getRoundNames(round.length)}</h4>
            {round.map((match, matchIndex) => (
              <Match
                key={match.matchId}
                match={match}
                reportIndex={[roundIndex, matchIndex]}
                locked={knockoutIsLocked}
              />
            ))}
          </div>
        ))}
      </div>
    </KnockoutTreeWrapper>
  );
};

export default KnockoutTree;
