import React from "react";
import { MaxWidthWrapper } from "../../ui/MaxWidth";
import { useCategory } from "../CategoryContext";
import {
  CategoryItemProvider,
  useCategoryItem,
} from "../CategoryItem/CategoryItemContext";
import { ContestPageTitle } from "../CategoryStartPage";
import ResultKnockout from "./ResultKnockout";
import ResultsTable from "./ResultsTable";

export const ResultItem = () => {
  const { item, isKnockout } = useCategoryItem();

  if (!item) return null;

  return (
    <MaxWidthWrapper>
      <ContestPageTitle className="group-name">
        Resultat {item.name}
      </ContestPageTitle>
      {isKnockout ? (
        <ResultKnockout />
      ) : (
        <ResultsTable group={item} className="toplist" />
      )}
    </MaxWidthWrapper>
  );
};

const ResultGroupMain = () => {
  const { hasKnockout } = useCategory();
  return (
    <CategoryItemProvider itemType={hasKnockout ? "knockouts" : "groups"}>
      <ResultItem />
    </CategoryItemProvider>
  );
};

export default ResultGroupMain;
