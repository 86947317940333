export function shuffleArray(array) {
  let currentIndex = array.length;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
  return array;
}

export function chunkArrayIntoN(arr, n) {
  const size = Math.ceil(arr.length / n);
  return Array.from({ length: n }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );
}

//MIRRORING THE DRAW
export function mirrorTheDraw(drawList) {
  let draw = [...drawList];
  const totPlayers = drawList.length;
  for (let i = 1; Math.pow(2, i) <= totPlayers / 4; i++) {
    const divider = Math.pow(2, i);
    const chunkDraw = chunkArrayIntoN(draw, divider);
    draw = [];
    for (let j = 0; j < chunkDraw.length; j++) {
      if (j % 4 === 1 || j % 4 === 2) draw.push(...chunkDraw[j].reverse());
      else draw.push(...chunkDraw[j]);
    }
  }
  return draw;
}

//KNOCKOUT
export function generateSeedingPattern(numPlayers) {
  if (numPlayers < 1) return [];
  if (numPlayers === 1) return [1];

  let seeds = [1, 2];
  let currentRoundSize = 2;

  while (currentRoundSize < numPlayers) {
    let newSeeds = [];
    let offset = currentRoundSize * 2;

    seeds.forEach((seed) => {
      newSeeds.push(seed);
      newSeeds.push(offset + 1 - seed);
    });

    seeds = newSeeds;
    currentRoundSize *= 2;
  }

  return seeds;
}

//KNOCKOUT DRAW
export function generateDraw(totPlayers, numByes) {
  if (totPlayers === 1) return [1];

  const seedPotential = totPlayers / 4;
  const numSeedsForCalculation =
    numByes > seedPotential ? numByes : seedPotential;

  const seeds = generateSeedingPattern(numSeedsForCalculation);

  //ACTUAL SEEDS
  const numSeeds = seeds.length;

  //CREATE ARRAY WITH THE REST PLAYERS
  const restPlayers = [];
  for (let i = numSeeds; i < totPlayers - numByes; i++) {
    restPlayers.push(i + 1);
  }

  //SHUFFLE REST PLAYERS
  const shuffledRestPLayers = shuffleArray(restPlayers);

  //INSERT REST PLAYERS IN BETWEEN SEEDS
  const drawList = [];
  for (let i = 0; i < numSeeds; i++) {
    drawList.push(seeds[i]);

    if (i % 2 === 0) {
      let byeCount = 0;
      if (seeds[i] <= numByes) {
        drawList.push("bye");
        byeCount++;
      }
      if (seeds[i + 1] <= numByes) byeCount++;

      drawList.push(
        ...shuffledRestPLayers.splice(
          0,
          (totPlayers - numSeeds) / (numSeeds / 2) - byeCount
        )
      );

      if (seeds[i + 1] <= numByes) drawList.push("bye");
    }
  }

  return mirrorTheDraw(drawList);
}

const generateMatchesInTree = (playerDraw) => {
  const rounds = [];
  let matchId = 1;
  const numberOfRounds = Math.log2(playerDraw.length);

  // Function to generate matches for first round
  const generateMatches = (players) => {
    const matches = [];
    for (let i = 0; i < players.length; i += 2) {
      const player1 = players[i];
      const player2 = players[i + 1];
      const match = {
        matchId: matchId++,
        player1,
        player2,
        winner:
          player1?.name === "bye"
            ? player2
            : player2?.name === "bye"
            ? player1
            : null,
      };
      matches.push(match);
    }
    return matches;
  };

  // Generate all rounds
  let currentPlayers = playerDraw;
  for (let i = 1; numberOfRounds >= i; i++) {
    const matches = generateMatches(currentPlayers);
    rounds.push(matches);
    // Get the winners for the next round
    currentPlayers = matches.map((match) => {
      const potentialPlayers = [
        ...(Array.isArray(match.player1) ? match.player1 : [match.player1]),
        ...(Array.isArray(match.player2) ? match.player2 : [match.player2]),
      ];
      return match.winner ? match.winner : potentialPlayers;
    });
  }

  console.log(rounds);

  return rounds;
};

//TYPE KNOCKOUT
export const generateKnockoutTree = (players) => {
  // Sort players by ranking points in descending order
  players.sort((a, b) => b.ranking - a.ranking);
  const playersList = [...players];

  // Calculate the next power of 2 greater than or equal to the number of players
  const nextPowerOf2 = (n) => Math.pow(2, Math.ceil(Math.log2(n)));
  const numPlayers = playersList.length;
  const numRequiredPlayers = nextPowerOf2(numPlayers);
  const numByes = numRequiredPlayers - numPlayers;
  const numSeeds = Math.ceil(numRequiredPlayers / 4);

  const draw = generateDraw(numRequiredPlayers, numByes);

  //PUT PLAYERS IN DRAW ORDER
  const playerDraw = draw.map((pos) => {
    // ADD BYE PLAYER TO BYE POSITION
    if (pos === "bye") return { name: "bye", ranking: 0, id: 0 };

    // ADD SEEDINGS NUMBER
    const player = playersList[pos - 1];
    if (pos <= numSeeds) player.seed = pos;

    return player;
  });

  return generateMatchesInTree(playerDraw);
};

const updateMatches = (roundPlayers, round) => {
  if (roundPlayers.length === 0) return null;
  for (let i = 0, j = 0; i < round.length; i += 1, j += 2) {
    const match = round[i];
    const newPlayer1 = roundPlayers[j];
    const newPlayer2 = roundPlayers[j + 1];

    if (
      match.player1?.id !== newPlayer1?.id ||
      match.player2?.id !== newPlayer2?.id
    ) {
      match.player1 = newPlayer1;
      match.player2 = newPlayer2;
      match.player1.set =
        match.player1.points =
        match.player2.set =
        match.player2.points =
          null;
    }
  }
};

export const updateKnockoutTree = (rounds) => {
  if (!rounds || rounds.length === 0) return null;

  let roundPlayers = [];
  for (let index = 0; index < rounds.length; index++) {
    const round = rounds[index];

    updateMatches(roundPlayers, round);

    roundPlayers = round.map((match) => {
      return match.winner
        ? match.winner
        : [
            ...(Array.isArray(match.player1) ? match.player1 : [match.player1]),
            ...(Array.isArray(match.player2) ? match.player2 : [match.player2]),
          ];
    });
  }
  return rounds;
};

function splitArrayOddEven(arr) {
  let odds = [];
  let evens = [];

  for (let i = 0; i < arr.length; i++) {
    if ((i + 1) % 2 !== 0) {
      odds.push(arr[i]);
    } else {
      evens.push(arr[i]);
    }
  }

  return [odds, evens];
}

export const byePlayer = { name: "bye", ranking: 0 };

//TYPE GROUP KNOCKOUT
export const generateKnockoutTreeFromGroups = (groupWinners, groupSeconds) => {
  const numPlayers = [...groupWinners, ...groupSeconds].length;

  //SPECIAL FIX FOR 1 AND 2 iIN EACH GROUP NEEDS TO PLACED ON EACH SIDE OF THE DRAW
  const groupWinnersTopBottom = splitArrayOddEven(groupWinners);
  const groupSecondsBottomTop = splitArrayOddEven(groupSeconds);

  const topBottomDraw = [
    [...groupWinnersTopBottom[0], ...groupSecondsBottomTop[1]],
    [...groupWinnersTopBottom[1], ...groupSecondsBottomTop[0]],
  ];

  const nextPowerOf2 = (n) => Math.pow(2, Math.ceil(Math.log2(n)));
  const numRequiredPlayers = nextPowerOf2(numPlayers);
  const numByes = numRequiredPlayers - numPlayers;

  // Add byes to the players list
  for (let i = 0; i < numByes / 2; i++) {
    topBottomDraw[0].push(byePlayer);
    topBottomDraw[1].push(byePlayer);
  }

  const numberOfRounds = Math.log2(numRequiredPlayers);

  // SEEDINGS
  for (let j = 0; j < topBottomDraw.length; j++) {
    for (let i = 0; i < numberOfRounds / 2; i++) {
      let out = [];
      const spliceSize = Math.pow(2, i);

      while (topBottomDraw[j].length > 0) {
        out = out.concat(topBottomDraw[j].splice(0, spliceSize));
        out = out.concat(topBottomDraw[j].splice(-spliceSize));
      }

      topBottomDraw[j].push(...out);
    }
  }

  const drawList = [];
  topBottomDraw.forEach((list) => {
    drawList.push(...list);
  });

  const draw = mirrorTheDraw(drawList);

  return {
    players: [...groupWinners, ...groupSeconds],
    rounds: generateMatchesInTree(draw),
  };
};
