export function sortPlayers(players) {
  return [...players].sort((a, b) => b.ranking - a.ranking);
}

export function addAndSort(players, player) {
  return sortPlayers([...players, player]);
}

export function addManyAndSort(players, newPlayers) {
  return sortPlayers([...players, ...newPlayers]);
}
