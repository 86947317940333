import { MaxWidthWrapper } from "../ui/MaxWidth";
import styled from "styled-components";
import { CategoryProvider, useCategory } from "./CategoryContext";
import SubMenu from "../SubMenu";
import { NavLink } from "react-router-dom";
import GearIcon from "../ui/FontIcons/GearIcon";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router";
import CategoryHeader from "./CategoryHeader";
import { set, type } from "./Settings/Settings";

const CategoryWrapper = styled.div``;

const CategoryStartWrapper = styled.div`
  text-align: center;
  margin: 0 0 2rem 0;
  > h2 {
    margin: 0 0 3rem 0;
  }
  .info-box-group {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px;
    margin: 20px 0;
    text-align: left;

    .info-box {
      padding: 15px;
      background: #f9f9f9;
      border: 1px solid #e0e0e0;
      border-radius: 8px;

      h3 {
        font-size: 1.2rem;
        margin: 0 0 10px;
      }

      p {
        margin: 0;
        font-size: 0.9rem;
        color: #555;
      }

      a {
        color: #007bff;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;

export const Category = () => {
  const {
    category,
    players,
    hasKnockout,
    hasGroups,
    hasSchedule,
    resultReady,
    knockoutStageOngoing,
    knockoutStageCompleted,
    isAdmin,
  } = useCategory();
  const location = useLocation();

  if (!category) return null;

  const isRoot =
    location.pathname === `/category/${category.id}` ||
    location.pathname === `/category/${category.id}/`;

  const groupCount = category.groups?.length;

  return (
    <CategoryWrapper>
      <CategoryHeader />
      <SubMenu className="sticky">
        <NavLink to="spelare">Spelare</NavLink>
        {hasGroups && <NavLink to="pooler">Pooler</NavLink>}
        {hasKnockout && <NavLink to="slutspel">Slutspel</NavLink>}
        <NavLink to="resultat">Resultat</NavLink>
        {isAdmin && (
          <NavLink to="installning">
            <GearIcon />
          </NavLink>
        )}
      </SubMenu>
      <Outlet />
      {isRoot && (
        <MaxWidthWrapper>
          <CategoryStartWrapper>
            <h2>{category.name}</h2>
            <div className="meta-info info-box-group">
              <div className="info-box">
                <h3>Datum</h3>
                <p>{category.date || "Ej angivet"}</p>
              </div>
              <div className="info-box">
                <h3>Tid</h3>
                <p>{category.time || "Ej angivet"}</p>
              </div>
              <div className="info-box">
                <h3>Spelform</h3>
                <p>
                  {type.find((x) => x.type === category.type).name ||
                    "Ej angivet"}
                </p>
              </div>
              <div className="info-box">
                <h3>Antal set</h3>
                <p>
                  {set.find((x) => x.value === category.set).name ||
                    "Ej angivet"}
                </p>
              </div>
            </div>
            <div className="stages info-box-group">
              <div className="info-box">
                <h3>Spelare</h3>
                <p>
                  {players?.length || 0}{" "}
                  <NavLink to="spelare">(Visa spelare)</NavLink>
                </p>
              </div>
              {hasGroups && (
                <div className="info-box">
                  <h3>Pooler</h3>
                  <p>
                    {hasSchedule ? (
                      <>
                        {groupCount}{" "}
                        <NavLink to="pooler">(Visa pooler)</NavLink>
                      </>
                    ) : (
                      "Ej lottat ännu"
                    )}
                  </p>
                </div>
              )}
              <div className="info-box">
                <h3>Slutspel</h3>
                <p>
                  {hasKnockout ? (
                    <>
                      {knockoutStageOngoing || knockoutStageCompleted ? (
                        <>
                          {knockoutStageCompleted ? (
                            <>Avslutat</>
                          ) : (
                            <>Pgående</>
                          )}{" "}
                          <NavLink to="slutspel">Gå till slutspel</NavLink>
                        </>
                      ) : (
                        "Ej startat"
                      )}
                    </>
                  ) : (
                    "Finns ej i denna spelform"
                  )}
                </p>
              </div>
              <div className="info-box">
                <h3>Resultat</h3>
                <p>
                  {resultReady ? (
                    <NavLink to="resultat">Visa resultat</NavLink>
                  ) : (
                    "Ej klart"
                  )}
                </p>
              </div>
            </div>
          </CategoryStartWrapper>
        </MaxWidthWrapper>
      )}
    </CategoryWrapper>
  );
};

const CategoryMain = () => {
  return (
    <CategoryProvider>
      <Category />
    </CategoryProvider>
  );
};

export default CategoryMain;
